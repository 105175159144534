import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getOnlineRegistration } from '../Service/Api';
const ErpLinks = () => {

   const [data, setData] = useState([]);

   useEffect(()=>{
     const getData =async ()=>{
       try {
         let registrationData = await getOnlineRegistration()
         setData(registrationData);
 
       } catch (error) {
         console.log(error)
       }
     }
 
     getData()
   },[])
 
 
   useEffect(() => {
     console.log("events_data", data);
 }, [data]);
 

  return (
     <>
     <div className="alumni-reg span3 wow rollIn" data-wow-delay="0.5s">
     <ul>
       <li><div style={{display:data.length>0?`block`:'none'}} className="online-reg"><h3> <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[0]}`} target="_blank" className=""> <i className="bi bi-paperclip"></i></Link><Link to="https://xavierbathinda.campuscare.info/" target="_blank">  {data[0]?.title}  </Link>
      </h3></div></li> 
     {/*   <li><Link to="https://xavierbathinda.campuscare.info/logon/alumniregistration" target="_blank"><img src="/Images/alumni.gif" className="img-fluid alumni float-end" alt="St. Xavier’s School Bathinda, CBSE School in Bathinda"/></Link></li>  */}
  {/* <li><Link to="#"><img src="/Images/tc.png" className="img-fluid tc float-end"/></Link></li>  */}
     <li><Link to="https://xavierbathinda.campuscare.info" target="_blank"><img src="/Images/Campulogo.png" className="img-fluid float-end campus" alt="St. Xavier’s School Bathinda, CBSE School in Bathinda"/></Link></li>   
       
     <li><Link to="/SxmunInformation"><img src="/Images/sxmun.gif" className="img-fluid float-end sxmun-2022 " alt="St. Xavier’s School Bathinda, CBSE School in Bathinda"/></Link></li>    
     <li className="OnlineR">
     <span className="bounce1">
       <Link to="/ClassroomTeaching" target="_blank" className="parantlogo">
          Classroom Teaching
       </Link>
     </span>
 </li>

     </ul>
     </div>
     </>
  )
}

export default ErpLinks
