import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'

const AdmissionWithdrawal = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Admission  Withdrawal</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Admission  Withdrawal</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul>
   <li>The Principal reserves the right of accepting or refusing any child who seeks admission to this school.</li>
   <li>No pupil will be admitted to this school unless her/his parent or guardian submits a Transfer Certificate from the school last attended by the pupil duly countersigned by the education authorities of the Concerned state/board for classes I to X and photocopy of Date of Birth Certificate.</li>
   <li>At the time of Registration of the pupil, the parent is required to submit to the school office a certified copy of the pupil's Birth Certificate from the Registrar of Birth and deaths/Municipal Corporation for classes L.K.G. and U.K.G.</li>
   <li>The date of birth, certified to be correct at the time of admission will not be altered on any account.</li>
   <li>Parents who wish to withdraw their ward must notify to the Principal in writing, (form is available in the office) of their intention at least one month prior to the withdrawal of the pupil. Those who are leaving after the academic session should inform in the month of February or fee for the first quarter will have to be paid.</li>
   <li>For Transfer Certificate Rs. 30 will be charged and in emergency Rs. 50 will be charged.</li>
   <li>The school authorities reserve the right to dismiss those pupils from the school whose conduct is found unsatisfactory, whose conduct is harmful to the school discipline or to other students and those who indulge in bullying, in any violence or repeatedly breaking pupil's code of conduct.</li>
   <li>A pupil's name may be struck off the school Register if she/he is absent from the school for more than 06 days without the Principal's prior permission. If he/she wishes to re-join this school, he/she will have to fulfill the conditions for admission like a new pupil.</li>
       </ul>
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/admission1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/admission.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
       </div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default AdmissionWithdrawal
