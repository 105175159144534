import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getNews } from '../../Service/Api';
const News = () => {

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNews();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" }
  ];


  return (
     <>
     <div className="col-12 col-sm-12 col-md-4 col-lg-4">
     <h1 className="sub_title">Latest <span>News</span></h1>
     {newsData.length > 0 ? newsData?.filter((item) => item.school === "Junior" || item.school === "Both" && item.category === "News").slice(0, 3).map((item, index) => (
     <article key={index}><Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><img src="/JuniorImages/attachment.png" className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div><h3>{item.title}</h3><p> {item.description}</p><div className="clr"></div></article>
    )) : 
    emptyArray.map((data, index) => (
<article key={index}><Link to="#" target="_blank"><img src="/JuniorImages/attachment.png" className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div><h3>{data.title}</h3><p>{data.description}</p><div className="clr"></div></article>
    ))
    }
    
     {/* <article>
     <Link to="#" target="_blank"><img src="/JuniorImages/attachment.png"  className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div>
     <h3>News Heading - 1</h3>
     <p>St. Xavier's School, Bathinda is an affiliate of the registered Society of Pilar, Punjab - Haryana, a branch of the Society of Pilar, GOA.</p>
     <div className="clr"></div>
     </article>
     <article>
     <Link to="#" target="_blank"><img src="/JuniorImages/attachment.png"  className="atta_icon" alt="St. Xaviers Bathinda" /></Link><div className="clr"></div>
     <h3>News Heading - 1</h3>
     <p>St. Xavier's School, Bathinda is an affiliate of the registered Society of Pilar, Punjab - Haryana, a branch of the Society of Pilar, GOA.</p>
     <div className="clr"></div>
     </article> */}


     <Link className="dedcription-btn" to="/JuniorNews"><span className="name-descripeion">View All</span><div className="btn-icon heart"><i className="fas fa-heartbeat"></i></div></Link>
     </div>
     </>
  )
}

export default News
