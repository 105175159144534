import React from 'react'
const HomeSlider = () => {
  return (
    <>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9"> 
    <div className="row slider-sec"> 
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel"> 
    <div className="carousel-indicators"> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 11"></button> <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 12"></button> </div> 
  <div className="carousel-inner"> 
    <div className="carousel-item active" data-bs-interval="2000"> <img src="/Images/sld2.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld3.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld4.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld6.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld7.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld8.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld11.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> 
  <div className="carousel-item" data-bs-interval="2000"> <img src="/Images/sld12.jpg" className="d-block w-100" alt="St. Xaviers Bathinda"/> </div> </div> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button> 
  </div> 
  </div>
   </div>
    </>
  )
}

export default HomeSlider
