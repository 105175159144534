import React, { useState, useEffect } from 'react';

const CountDown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div id="countdown" className="countdown">
      <ul>
        <li><span id="days">{timeLeft.days}</span> Days</li>
        <li><span id="hours">{timeLeft.hours}</span> Hours</li>
        <li><span id="minutes">{timeLeft.minutes}</span> Minutes</li>
        <li><span id="seconds">{timeLeft.seconds}</span> Seconds</li>
      </ul>
    </div>
  );
};

export default CountDown;