import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getCircular } from '../../Service/Api';

const JuniorSyllabus = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getCircular();
                setData(galleryData);

                const uniqueYears = Array.from(new Set(galleryData.map(item => item.fromDate && new Date(item.fromDate).getFullYear()))).sort((a, b) => b - a);
                setYearRanges(uniqueYears);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        let monthsSet = new Set();
        let filtered = data.filter(item => {
            const itemDate = item.fromDate ? new Date(item.fromDate) : null;
            if (itemDate) {
                if (selectedYear !== "All" && itemDate.getFullYear() !== parseInt(selectedYear)) {
                    return false;
                }
                monthsSet.add(itemDate.getMonth() + 1);
                if (selectedMonth !== "All" && (itemDate.getMonth() + 1) !== parseInt(selectedMonth)) {
                    return false;
                }
                return true;
            }
            return false;
        });

        setMonthOptions(["All", ...Array.from(monthsSet).sort().map(m => monthNames[m - 1])]);

        if (searchQuery) {
            filtered = filtered.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        setFilteredData(filtered);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
            <div className="kids">
                <div className="container juniorcontainer">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <Link to="/JuniorHome"><img src="/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <Link to="/JuniorHome"> <img src="/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></Link>
                            <div className="kintergarten_slider_sec_btm_bird"><img src="/JuniorImages/bird.gif" className="img-fluid"/></div>
                        </div>
                    </div>
                    <div className="kids-back-sec">
                        <h2> Syllabus</h2>
                        <div className="news-inner">
                            <div className="row tabs-dynamic">
                                <div className="col-md-3">
                                    <div className="count-val">
                                        <p>Total Count: {filteredData.length}</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="month-selection">
                                        <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                            <option value="All">All Years</option>
                                            {yearRanges.map((yr, index) => (
                                                <option key={index} value={yr}>{yr}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="month-selection">
                                        <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                            <option value="All">All Months</option>
                                            {monthOptions.map((month, index) => (
                                                <option key={index} value={index}>{month}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                                    <div className="searchBox">
                                        <input
                                            type="text"
                                            id="myInput"
                                            name="name"
                                            autoComplete="off"
                                            placeholder="Search Here.."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row circular_sec">
                                <div className="col-lg-12">
                                    {filteredData.length > 0 ? filteredData.map((item, index) => (
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth">
                                            <article>
                                                <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} target="_blank">
                                                    <img src="images/magzine.png" className="img-fluid" alt={item.title} />
                                                    <h5>View</h5>
                                                    <h4>{item.title}</h4>
                                                </Link>
                                            </article>
                                        </div>
                                    )) : <p>No data found</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default JuniorSyllabus;
