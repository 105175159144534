import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {NavLink, Link } from 'react-router-dom'
const ParentPortal = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Portal Portal Guidelines </h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Portal Portal Guidelines </span></h5>
     </div>
     
     </div>
     <div className="innerpage">
        <div className="row inner_cont">
        <div className="col-lg-9 col-md-8 col-sm-12 col-12">
        <p className="guideheading">CampusCare®  Application Guidelines &amp; Instructions </p>
        <div className="clr10"></div>
        <p className="guideheading"><strong>How to LOG IN Parent Portal?</strong></p>
        <div className="clr10"></div>
        <p className="details"> 1. Open Chrome<img src="/images/google.png" alt="School ERP Software, Mobile App, School Management Software"/> OR Mozilla <img src="/images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software"/> or Internet <img alt="School ERP Software, Mobile App, School Management Software" src="/images/internet.png"/>  </p>
        <p className="details"> 2. Write the school URL <strong> <Link  target="_blank" to="https://xavierbathinda.campuscare.info/">"xavierbathinda.campuscare.info"</Link></strong> in address bar.
        (you will be routed to the Log In credential page).</p>
            <p className="details"> 3. Then, enter the USER ID and Click on  "Next button", Then enter
        PASSWORD and Click on Sign in  button.</p>
            <p className="details">Once logged in, you are welcomed with various information
        relating to assignments, news, circulars, date sheet, fee details etc. that
        are self explanatory. Wish you to go through every links that are
        provided and explore more.</p>
        
        <div className="clr10"></div>
        <p className="guideheading"><strong>How to RESET PASSWORD in Parent Portal? </strong></p>
        <div className="clr10"></div>
        <p className="details"> 1. Open Chrome <img src="/images/google.png" alt="School ERP Software, Mobile App, School Management Software"/> OR Mozilla <img src="/images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software"/> or Internet <img alt="School ERP Software, Mobile App, School Management Software" src="/images/internet.png"/>  </p>
        <p className="details"> 2. Write the school URL <strong> <Link  target="_blank" to="https://xavierbathinda.campuscare.info/">"xavierbathinda.campuscare.info"</Link></strong> in address bar.
        (you will be routed to the Log In credential page).</p>
        <p className="details">3. Then, click on “Forgot User ID or Password?” text given below User ID field.</p>
        <p className="details">4. Select the User Type (For which you want a password) then Enter Registered Mobile No. and click on “Reset” Button.</p>
        <p className="details">5. You will get an SMS on your registered mobile no. along with login credentials. 
        </p>
                  <div className="clr10"></div>
                  <div>
        <p className="guideheading"><strong> How to Install &amp; operate MOBILE APP? </strong></p>
                <p>1. Go to <img alt="School ERP Software, Mobile App, School Management Software" src="/images/playstore.png"/> Store / <img alt="School ERP Software, Mobile App, School Management Software" src="/images/apple.png"/>  and Search 
        
        "CampusCare®". </p>
                <p> 2. Install and open the  <Link  to="https://www.entab.in/mobile-apps.aspx"><b>Mobile App</b></Link>.</p>
                <p>3. Enter school web portal URL: <strong> <Link  target="_blank" to="https://xavierbathinda.campuscare.info/">"xavierbathinda.campuscare.info"</Link></strong> in link bar      &amp; verify. </p>
                <p>4. After verifying, you will get an option for User ID &amp; Password.</p>
                <p>Once logged in, you are welcomed with various information relating to assignments, news. circulars, date sheet, fee details etc that are self Explanatory. Wish you to go through every links that are 
        
        provided and      explore more. </p>
                <p><strong>NOTE* :</strong> Kindly update your  <Link  to="https://www.entab.in/mobile-apps.ahtmlspx"><b>Mobile Apps</b></Link> time to time to avoid difficulties during usage.</p>
            </div>
            <div className="clr10"></div>
            <div>
                      <p className="guideheading"><strong>How to make Online Payment? :-</strong> </p>
                      <p> 1. Open Google Chrome <img src="/images/google.png" alt="School ERP Software, Mobile App, School Management Software"/> / Mozilla Firefox <img src="/images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software"/> or Internet Explorer <img src="/images/internet.png" alt="School ERP Software, Mobile App, School Management Software"/> </p>
                      <p> 2. Enter the school URL <strong> <Link  target="_blank" to="https://xavierbathinda.campuscare.info/">"xavierbathinda.campuscare.info"</Link></strong> address bar and you will be routed to the Log In page.</p>
                      <p> 3. Now, enter the USER ID and then enter your PASSWORD and Click on ‘Sign in’ button.</p>
                      <p> 4. After login, click on the Online Payment menu.</p>
                      <p> 5. Select the installment to pay &amp; click on the proceed button.</p>
                      <p> 6. Now, you will be redirected to Payment Gateway page.</p>
                      <p> 7. Choose the Pay mode : Credit Card/Debit Card/Net banking &amp; fill the details to complete the payment process.</p>
                      <p> 8. After successful payment, you will receive the fee receipt &amp; SMS on successful fee payment.</p>
                      <div className="clr25"></div>
                      <p><b>Note :-</b><i> If for any reason, the fee payment output details are not displayed on your screen after you have made payments, please check the details in “My Payment” in Finance link. If your accounts get debited/deducted and receipt is not available, you are advised to contact school office or mail us.</i></p>
                  </div>
        
        
                  <div className="clr10"></div>
        <div className="assistance">
        <h4 className="guideheading">Please call or write for assistance:</h4>
        <p className="details"><strong>  <Link  to="https://www.entab.in/contact-us.aspx">PARENT HELP DESK</Link></strong> ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333 - Ext : 5,<strong> E-Mail :</strong> <strong> <Link  to="mailto:parentdesk@entab.in" className="yellow-link"> parentdesk@entab.in</Link></strong></p>
        </div>
        
        <div className="clr"></div>
        </div>
           <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                    <div className="important_menu">
                    <h3>Important Menu</h3>
                    <ul>
                    <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
                    <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
                    <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
                    <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
                    <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
                    <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
                    </ul>
                    <div className="clr"></div>
                    </div>
             </div>
        </div>
      </div>
   <Footer/>
  </>
  )
}

export default ParentPortal
