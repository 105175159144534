import React from 'react'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import Gallery from'../Component/Gallery'
import News from'../Component/News'
import Events from'../Component/Events'
import Erplinks from'../Component/Erplinks'
import Highlights from'../Component/Highlights'
const JuniorHome = () => {
  return (
   <>
     <div className="juniorbg">
      <Erplinks/>
      <Highlights/>
     <header>
     <div className="row logo_menu" data-fly-in-distance="30%" >
        <div className="col-xl-4 col-12 logo_sec"><center><Link to="./"><img src="/JuniorImages/logo_junior.jpg" alt="St. Xaviers Bathinda" /></Link></center></div>
        <div className="col-xl-8 col-12 menu_sec">
        <nav id="menu">
        <input type="checkbox"/>
        <label>&#8801;<span>Menu</span></label>
        <ul>
        <li><Link to="../JuniorNews" target="_blank">Latest News</Link></li>
        <li><Link to="../JuniorEvents" target="_blank">Upcoming  Events</Link></li>
        <li><Link to="../LeapDay" target="_blank">Leap Day</Link></li>
        </ul>
        </nav>
        </div>
        <div className="clr"></div>
     </div>
     </header>
     <div className="slider_sec">
     <div className="pic-wrapper">
     <figure className="pic-1"></figure>
     <figure className="pic-2"></figure>
     <figure className="pic-3"></figure>
     <figure className="pic-4"></figure>
     </div>
     <div className="junior_link"><Link to="../"><img src="/JuniorImages/senior.gif" alt="St. Xaviers Bathinda" /><h3>Senior School </h3></Link></div>
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
     <div className="bottom_banner_top"></div>
     <div className="bottom_banner">
     <div className="row">
     <div className="col">
     <article>
     <Link to="../JuniorSyllabus"><img src="/JuniorImages/syllabus.png" alt="St. Xaviers Bathinda" /></Link>
     <h3><Link to="../JuniorSyllabus">Syllabus</Link></h3> 
     <p>A syllabus or specification is a document that communicates information about a specific academic course</p>
      </article>
     </div>
     <div className="col">
     <article>
     <Link target="_blank" to="https://xavierbathinda.campuscare.info/"><img src="/JuniorImages/software.png"  alt="St. Xaviers Bathinda" /></Link>
     <h3><Link target="_blank" to="https://xavierbathinda.campuscare.info/">CampusCare</Link></h3>
     <p>Mobile Apps are designed to work with school ERP software</p> 
      </article>
     </div>
     <div className="col">
     <article>
     <Link to="../JuniorCircular"><img src="/JuniorImages/document.png"  alt="St. Xaviers Bathinda" /></Link>
     <h3><Link to="../JuniorCircular">Circulars</Link></h3> 
     <p>A circular is an official letter that is sent to a large number of people at the same time.</p> 
      </article>
     </div>
     <div className="col">
     <article>
     <Link to="../JuniorVideo"><img src="/JuniorImages/video-camera.png"  alt="St. Xaviers Bathinda" /></Link>
     <h3><Link to="../JuniorVideo">Video</Link></h3> 
     <p>Video is an electronic medium for the recording, copying, playback, broadcasting</p> 
      </article>
     </div>
     <div className="col">
     <article>
     <Link to="../JuniorEMagazine"><img src="/JuniorImages/magazine.png"  alt="St. Xaviers Bathinda" /></Link>
     <h3><Link to="../JuniorEMagazine">E-Magazine</Link></h3> 
     <p>An eMagazine is a digital magazine that provides its readers with targeted information and news.</p> 
      </article>
     </div>
     
     </div>
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
     <div className="welcome_sec" data-fly-in-distance="30%" >
     <div className="row">
     <div className="col-12 col-sm-12 col-md-4 col-lg-4">
     <img src="/JuniorImages/news_kids.png" className="img-fluid tree" />
     </div>
     <div className="col-12 col-sm-12 col-md-4 col-lg-4">
     <h1>Welcome to Our Junior School</h1>
     <p>St. Xavier's School, Bathinda is an affiliate of the registered Society of Pilar, Punjab - Haryana, a branch of the Society of Pilar, GOA. The latter runs a number of other social activities like dispensaries, grihini schools, orphanages, balwadis etc.
     St. Xavier's School, Bathinda was opened in 1983, as a response to the pressing educational needs of the town of Bathinda and the surrounding villages. Originally an all-boys school, it opened its portals to girls in April 1990. The aim of the school is not only to prepare the students academically, but also to cater to the all-round development of the child, especially its moral and intellectual qualities. In this way, the school strives to draw out the best from each individual and enable its pupil to blossom into mature citizens of our country. The school is owned and managed by a religious group, which is a legitimate right provided for minority communities in our Constitution.</p>
     <center><Link className="dedcription-btn" to="#"><span className="name-descripeion">Read More</span><div className="btn-icon"><i className="far fa-lightbulb"></i></div></Link></center>
     </div>
     <div className="col-12 col-sm-12 col-md-4 col-lg-4">
     <img src="/JuniorImages/news_kids.png" className="img-fluid tree" />
     </div>
     </div>
     </div>
     <div className="clr"></div>
     <div className="clr"></div>
     <div className="activities_sec">
     <div className="container juniorcontainer">
     <h1>Our Activities</h1>
      <News/>
     <div className="col-12 col-sm-12 col-md-4 col-lg-4" ><img src="/JuniorImages/activities.jpg" className="center_img" alt="St. Xaviers Bathinda" /></div>
      <Events/>
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
     </div>
     <div className="gallery_junior_sec" data-fly-in-distance="30%" >
         <Gallery/>
     <div className="clr"></div>
     </div>
     <Footer/>
     </div>
   </>
  )
}

export default JuniorHome
