import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import BulletinSidebar from'./BulletinSidebar'
import { Link } from 'react-router-dom'
const Teachers = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Teachers</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Bulletin <i className="bi bi-arrow-right"></i>  Seminars, Webinars, Conferences    <i className="bi bi-arrow-right"></i>  Teachers</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 circular_sec">
        <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
        <article> <Link to="/pdf/Dengue-Prevention-Programme.pdf" target="_blank"> <img src="Images/circular.png" className="img-fluid" alt="St. Xaviers Bathinda"/>
          <h5>View</h5>
          <h4> Dengue Prevention Programme</h4>
          </Link> </article>
      </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/REPORT-SEMINAR-SILVIA.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4> IGNITING THE SPARK WITHIN TEACHERS AND LEAVE POLICY</h4>
              </Link></article>
          </div>




        <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/report-workshop.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4> Workshop for Teachers</h4>
              </Link></article>
          </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Gala-Time-For-Teachers.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4> Gala Time For Teachers</h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Awareness-Programme-Dengue.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Awareness Programme on Dengue</h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Grooming-Session-Faculty-Members.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Grooming Session For Faculty Members</h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/GROOMING-PERSONALITY-DEVELOPMENT-WORKSHOP.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>GROOMING AND PERSONALITY DEVELOPMENT WORKSHOP</h4>
              </Link></article>
          </div> 
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Training-Sessions-for-Staff-Members.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Training Sessions for Staff Members</h4>
              </Link></article>
          </div> 
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/HEATHLY-MIND-CREATING-HEALTHY-MINDS.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Healthy Mind Creating Healthy Minds</h4>
              </Link></article>
          </div> 
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/VISION-FOR-MATHS.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Vision For Maths</h4>
              </Link></article>
          </div> 
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Experiential-Training-Programme.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Experiential Training Programme</h4>
              </Link></article>
          </div> 
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/webinar-teachers1.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Insight on School Counselling and Treatment Plans </h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/webinar-teachers-2.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Experiential Learning </h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/webinar-teachers-3.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>National Education Policy 2020</h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/Training.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>Training</h4>
              </Link></article>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth">
            <article> <Link to="/pdf/WEBINAR.pdf" target="_blank"> <img src="/Images/circular.png" className="img-fluid"  alt="St. Xaviers Bathinda"/>
              <h5>View</h5>
              <h4>WEBINAR</h4>
              </Link></article>
          </div>
        </div>
        <div className="clr"></div>
      </div>
<BulletinSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Teachers
