import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const AboutSchool = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>About School</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> About School</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12">
<p className="large_font">St. Xavier's School, Bathinda is an affiliate of the registered Society of Pilar, Punjab - Haryana, a branch of the Society of Pilar, GOA. The latter runs a number of other social activities like dispensaries, grihini schools, orphanages, balwadis etc.</p>
<p className="large_font">St. Xavier's School, Bathinda was opened in 1983, as a response to the pressing educational needs of the town of Bathinda and the surrounding villages. Originally an all-boys school, it opened its portals to girls in April 1990. The aim of the school is not only to prepare the students academically, but also to cater to the all-round development of the child, especially its moral and intellectual qualities. In this way, the school strives to draw out the best from each individual and enable its pupil to blossom into mature citizens of our country. The school is owned and managed by a religious group, which is a legitimate right provided for minority communities in our Constitution.</p>
<p className="large_font">The school offers diversity of academic facilities. It has also introduced computer science, classical dance and music as part of its curriculum. The program of studies is in accordance with the syllabus laid down by the Central Board of Secondary Education, Delhi. While the medium of instruction is English, the school also lays special emphasis on teaching Punjabi, Hindi and Sanskrit as additional languages.</p>
<div className="clr"></div>
    <div className="row">
<div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/about.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
<div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/about1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
    </div>
<div className="clr"></div>
</div>

<AboutSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default AboutSchool
