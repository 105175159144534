import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const Goals = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Goals</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Goals</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <p className="large_font">St. Xavier's perceives each of its students, as free men and women of our pluralistic society, who through its thought process and human engagement contribute towards a more integrated, rational, egalitarian and caring society.</p>
   <p className="large_font">St. Xavier's students will be confident and life-long learners responsible for their learning, risk-takers and through their positive efforts contribute towards the growth of the community they belong to.</p>
   <p className="large_font">They will be required to acquire the ability to do complex thinking and problem solving and the ability to qualitatively improve products and processes that affect them and their community.</p>
   <p className="large_font">Driven by this personal conviction to make a difference, each Xavierite strives towards :</p>
   <ul>
   <li>Promoting academic excellence, through critical thinking and active learning in the classrooms aided by a well trained faculty and state of art technology in the campus.</li>
   <li>Advancing a thriving intellectual environment in the School and promoting wellness to develop leadership and fellowship.</li>
   <li>Fostering students' self-confidence and encouraging both responsibility and innovation through classroom experiences, collaborative leadership and citizenship by participation in activities, sports, clubs and students-faculty projects.</li>
   </ul>
   <p className="large_font">As an institution of learning, we engage our students in the quest for knowledge and truth, believing that such engagement will equip them to make sound judgements as individuals, family members and citizens. We provide individual guidance to students as they strive for academic excellence and develop values that will guide them in meaningful and productive lives. Our dual emphasis on intellectual and personal growth flows from our belief that learning is valuable for its own sake, for the sake of those who learn, and for the sake of society as a whole.</p>
   <div className="clr"></div>
   <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/goals.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/goals1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
       </div>    
   <div className="clr"></div>
   </div>

<AboutSidebar/>
</div>
</div>
  <Footer/>
  </>
  )
}

export default Goals
