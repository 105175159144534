import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
const HomeToppers = () => {
  var settings = {  
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow:2,
    slidesToScroll: 1,
    initialSlide: 0, 
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
      
        {
          breakpoint: 1300,
          settings: {
            slidesToShow:2, 
          }
        },
        {
            breakpoint: 1070,
            settings: {
              slidesToShow: 2, 
            }
          },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2, 
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:2, 
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:1, 
          }
        }
      ]
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("topper_data", data);
    console.log("topper_data", data.length +'test');
  }, [data]);

  const emptyArray = [
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/toppers.png",
    },
  ];


  return (
     <>
     <Slider {...settings}> 
     {data.filter((item)=>(item.class==='X')).length > 0
            ? data
                .filter((item) => item.class === "X")
                .map((item, index) => (
                  <div key={index}>
          <div className="card p-4"> <h4>{item.division}</h4> 
          <img 
  src={item.attachments && item.attachments[0] ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : "Images/gallery-pic.jpg"} 
  className="img-fluid" 
  alt={item.title}
/>
          <div className="card-body"><h5>{item.name}</h5><p>{item.stream}</p></div></div>
          </div>     

          ))
            : emptyArray.map((data, index) => (
              <div key={index}>
              <div className="card p-4"> <h4>{data.division}</h4> <img src={data.attachments} className="card-img-top" alt="St. Xaviers Bathinda" />
              <div className="card-body"><h5>{data.name}</h5><p>{data.stream}</p></div></div>
              </div>
              ))}

          {/* <div>
             <div className="card p-4"><h4>98.5%</h4> <img src="/Images/toppers.png" className="card-img-top" alt="St. Xaviers Bathinda" />
               <div className="card-body">
                 <h5>Student Name</h5>
                 <p>Subject</p>
               </div>
             </div>
           </div>

           <div>
             <div className="card p-4"> <h4>98.5%</h4><img src="/Images/toppers.png" className="card-img-top" alt="St. Xaviers Bathinda" />
               <div className="card-body">
                 <h5>Student Name</h5>
                 <p>Subject</p>
               </div>
             </div>
           </div>  */}
       </Slider>
     </>
  )
}

export default HomeToppers
