import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'

const LibraryRules = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Library Rules</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Library Rules</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul>
   <li>Silence is to be observed in the library.</li>
   <li>The school maintains a library for the students from std. IV and above.</li>
   <li>Students are not allowed to keep any book for more than 15 days, unless they are re-issued.</li>
   <li>No book can be re-issued unless it is actually brought to school.</li>
   <li>Books are issued for the use of borrower only.</li>
   <li>Students are expected to take utmost care of library books. Therefore, they should be examined by the borrower before leaving the class or library. If a book is damaged, the librarian or the class teacher must be informed immediately. Damages sustained by the book while out of the library will be laid to the borrower's account and he/she will have to make good the damage.</li>
   <li>In case of absence, the library book must be brought to school on the day when the student attends the school.</li>
   <li>Failure to return a book on the expiry of the period of 15 days will involve payment of a fine of Rs. 10 per day (inclusive) of holidays and Sundays.</li>
   <li>In case of a holidays, the library book should be submitted on the following class day.</li>
   <li>New book will not be issued unless the old one is returned.</li>
   <li>A pupil must read at least 10 books during the academic year.</li>
   <li>Students should not exchange the school library books among themselves.</li>
   <li>Before asking for a new book, students must have their old book duly crossed in their library card.</li>
   <li>It is strictly forbidden to write any remark in a book. Writing of any kind in the books or even underlining of words is punishable with a fine. If there are any such marks in the books already, they should be shown to the librarian or to the class teacher immediately on the receipt of the book, and not later.</li>
   <li>Reference books eg. Encyclopedia, Magazine etc. will not be issued.</li>
   <li>No student is allowed to bring his/her personal books, or bag in the library. Only a note-book will be allowed, with the permission of the librarian.</li>
   <li>Students should maintain a library copy where in they write a central idea of the book read.</li>
   </ul>
   <div className="clr"></div>
       <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/library_1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
   <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/library_2.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
       </div>
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default LibraryRules
