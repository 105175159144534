import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const OurPatron = () => {
   return (
    <>
<Header/>   
   <div className="inner_slider">
   <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Our Patron</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Our Patron</span></h5>
   </div>
   
   </div>
   <div className="innerpage">
<div className="row inner_cont">
  <div className="col-lg-9 col-md-8 col-sm-12 col-12">
  <p className="large_font">St. Francis Xavier who was born in Spain in 1506 to a noble family, was influenced by Ignatious Loyola. Ignatious Loyola who started the Society of Jesus, recruited Francis Xavier into this order.</p>
  <p className="large_font">St. Francis Xavier who landed in Goa during 1542 specifically to stem the rot amongst the faithful, in a short period of time caused a major awakening. He was instrumental in building many churches and institutions in Goa and elsewhere. He travelled across Malacca, China and Japan.</p>
  <p className="large_font">St. Francis Xavier, the patron saint of Goa, affectionately called as Goencho Saiba or Lord of Goa died in 1552 on his way to China. The body brought in 1554 to Goa, is kept in the Basilica of Bom Jesus. The sacred relic of St. Francis Xavier normally housed in a silver casket in the Bom Jesus church, is brought in a ceremonious procession by several priests to the SE cathedral every 10 years for an exposition.</p>
  <p className="large_font">He was beatified by Pope Paul V in 1619 and canonized together with St. Ignatius by Pope Gregory XV, on March 12th, 1622.</p>
  <div className="clr"></div>
      <div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/patron.jpg" alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-6"><center><img src="/Images/patron1.jpg"  alt="St. Xaviers Bathinda" className="sec_imgaes_new img-fluid" /></center></div>
          </div>
  <div className="clr"></div>
  </div>

<AboutSidebar/>
</div>
</div>
 <Footer/>
  </>
  )
}

export default OurPatron
