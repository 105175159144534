import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ActivitiesSidebar from'./ActivitiesSidebar'
import {SlideshowLightbox} from 'lightbox.js-react'
import { Link } from 'react-router-dom'
const SocialOutreach = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Social Outreach</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Activities <i className="bi bi-arrow-right"></i> Social Outreach</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 memories_sec yeartab">
        <h3>A Visit to A Slum</h3>
        <p><strong>THEME:  Hygiene, Venue - Dhobiana Basti , Date-29th July 2022 </strong></p>
        <p><strong>“In every community, there is work to be done. In every nation, there are wounds to heal. In every heart, there is the power to do it.”</strong></p>
        <p>The Nukkad Natak programme was organised to raise awareness about the importance of hygiene among the slum dwellers. The students of class V along with the students of the ministry performed a nukkad natak. </p>
        <p>Soaps were distributed among the people.</p>
        <p>The competition was conducted by under the kind supervision of Mrs Mamta Yadav (Advisor of Social Welfare) and Mrs Rajbeer Kaur (Advisor of S.I.G) Mrs. Sonia Jindal (Activity Coordinator) and Miss Nupur (Supervisor).</p>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/Nukkad_Natak_1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/Nukkad_Natak_2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/Nukkad_Natak_3.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <h3>CELEBRATING 75th INDIA’S INDEPENDENCE WITH 75 SAPLINGS</h3>
        <p>St. Xavier’s School, Bathinda celebrated the 75th Independence day of the country in the most unique manner. Around 20 students from class VIth accompanied with 4 school teachers were taken to an upcoming government school near the stadium and they planted 75 saplings in the premises of the school. The school in collaboration with ‘The Pillar Outreach Foundation’ and ‘Tree lover society’ initiated this movement and the students loved to be a part of the plantation drive. Such practical orientation programmes help the children to be sensitive about the environment and sends a very significant message to the society.</p>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/outrach_1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/outrach_2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                <SlideshowLightbox><img src="Images/outrach_3.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
      </div>
<ActivitiesSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default SocialOutreach
