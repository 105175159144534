import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ProspectSidebar from'./ProspectSidebar'
import { Link } from 'react-router-dom'
const ProcessAdmission = () => {
  return (
    <>
<Header/>
    <div className="inner_slider">
    <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
    <div className="row bread_cumb">
    <h2>Process of Admission for LKG</h2>
    <div className="theme-heading">
    <span className="colorW marB10"><i className="bi bi-building"></i></span>
    </div>
    <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>  Prospectus <i className="bi bi-arrow-right"></i> Process of Admission for LKG</span></h5>
    </div>
    
    </div>
    <div className="innerpage">
<div className="row inner_cont">
   <div className="col-lg-9 col-md-8 col-sm-12 col-12">
   <ul className="nav nav-tabs" id="myTab" role="tablist">
   <li className="nav-item" role="presentation">
     <button className="nav-link active" id="year2024-25-tab" data-bs-toggle="tab" data-bs-target="#year2024-25" type="button" role="tab" aria-controls="year2024-25" aria-selected="true"> 2024-25</button>
   </li>
   <li className="nav-item" role="presentation">
     <button className="nav-link" id="year2023-24-tab" data-bs-toggle="tab" data-bs-target="#year2023-24" type="button" role="tab" aria-controls="year2023-24" aria-selected="false"> 2023-24</button>
   </li>
   <li className="nav-item" role="presentation">
     <button className="nav-link" id="year2022-23-tab" data-bs-toggle="tab" data-bs-target="#year2022-23" type="button" role="tab" aria-controls="year2022-23" aria-selected="false"> 2022-23</button>
   </li>
   <li className="nav-item" role="presentation">
     <button className="nav-link" id="year2021-22-tab" data-bs-toggle="tab" data-bs-target="#year2021-22" type="button" role="tab" aria-controls="year2021-22" aria-selected="false"> 2021-22</button>
   </li>
 </ul>
 <div className="tab-content" id="myTabContent">
   <div className="tab-pane fade show active" id="year2024-25" role="tabpanel" aria-labelledby="year2024-25-tab">
     
         <p><strong>IMPORTANT INFORMATION REGARDING ADMISSION TO LKG – 2024-2025</strong></p>
       <p> 1. <strong>Age Eligibility: </strong> The candidate seeking admission to LKG in the year <strong>2024-2025</strong> should be born between <strong>01/01/2020 and 31/12/2020</strong>. The candidate will <strong>NOT</strong> be eligible for admission if he/she is <strong> NOT</strong> within the specified age limit. </p>
    <p> 2. <strong>Date of Birth Certificate: </strong> The original Date of Birth Certificate issued by the Municipal Corporation, Baptism Certificate (in case of Christian children only) along with a photostat copy duly attested by a Class A gazetted officer must be submitted at the time of verification of the documents. <strong>(Note: No Notary attested copy will be accepted) </strong></p>
    <p> 3. <strong> Parents’ Qualification Certificates and Aadhar Card Copy: </strong> The original qualification certificates and Aadhar card of both the parents along with a Photostat copy duly attested by a <strong>Class A </strong> gazetted officer must be submitted at the time of verification of the documents. <strong>(Note: No Notary attested copy will be accepted)</strong> </p>
    <p> 4. <strong>Proof of residence of the parents: </strong> Submit a photostat copy of any one of the following documents duly attested by a <strong>Class A</strong> gazetted officer at the time of verification of the documents: <strong>(Note: No Notary attested copy will be accepted)</strong> </p>
    <p>a) Voter ID Card </p>
    <p>b) Electricity Bill </p>
    <p>c) Aadhaar Card </p>
    <p>d) Ration Card </p>
    <p>e) Passport </p>
    <p>f) Rent Deed (If Staying on Rent) </p>
    <p><strong> Note:  The original certificate will be returned to the parents immediately after
        the verification. Submit photocopy attested by Class A gazette officer
        only. No Notary attested copy will be accepted. </strong></p>
    <p>5. <strong> Name and other particulars: </strong> Fill in the particulars of the candidate correctly (i.e. name, parents’ name, date of birth). <strong> The date of birth filled in the form must tally with the one written in the D.O.B Certificate. </strong> All names should be spelt correctly in capital letters as they will be required in all the legal papers and <strong>CBSE </strong>records. No changes with regard to Date of Birth whatsoever will be entertained once the admission to LKG or any subsequent classes are done. </p>
    <p>6. <strong> Photographs: </strong> The latest photograph (taken not more than a month before the date of application) of the candidate is to be uploaded in the space provided for it. Individual photographs of the parents and a family photograph (showing both the parents and the candidate) must be uploaded in the space. All the photographs should be with red background and in JPG format with size less than 20KB. Kindly bring the first two photographs at the time of the verification of documents. </p>
    <p>7. <strong> Selection: </strong> After having set aside, a quota of seats to be filled on the recommendation of the management, the school shall in its sole discretion have the right to grant priority in admission to persons fulfilling the following criteria: </p>
    <ul>
        <li> The children of Staff Members of the school will be considered for admissions provided other basic criteria for admission to the school are fulfilled. </li>
        <li> Being a Christian Minority institution, children belonging to that minority community will be considered for admission provided they meet the other requirements of the institution. </li>
        <li> Other applications will be considered for admissions thereafter, by the picking up of lots. </li>
        <li> Once admission has been granted, all formalities including payment of the stipulated fees must be completed within the time assigned, failing which it will be deemed that the claim for admission has been forfeited. </li>
        <li> The Management of the School reserves all rights of admission or rejection and is not bound to give reasons for admission or rejection of any particular candidate. </li>
    </ul>
    <p>8. <strong>Draw of Lots: </strong> </p>
    <ul>
        <li> Draw of lots to be held online. </li>
        <li> The Registration Number of the candidates whose slip is drawn will be announced during the draw. </li>
    </ul>
    <p>9. <strong> Rejection of Form: </strong> A registration form is liable to be rejected incase of incomplete or incorrect information and no representation will be entertained thereafter. </p>
    <p><strong> Caution:  The School does not accept any donation for admissions. Parents should be aware of third parties collecting money on behalf of the School and making false claims of procuring admission. If the parents enter into any transaction with such parties, they will be doing so at their own risk and the School shall not be responsible for it. </strong></p>
    <p>10. <strong>Regarding Enquiry: </strong> In case you have a query or need any help, kindly email us at <Link to="mailto:contactsaintxaviersbathinda@gmail.com">
        contactsaintxaviersbathinda@gmail.com</Link>. The relevant queries will be replied as soon as possible. </p>
        <p> 11. <strong> Registration Fee: </strong> Rs.1000/- is to be paid online and is nonrefundable. </p>
    <p> 12. <strong> Fee Structure: </strong> For fee structure, kindly refer to the school website
        <Link to="mailto:www.xavierbathinda.com">www.xavierbathinda.com </Link> </p>
    <p> 13. <strong>Acknowledgement Receipt: </strong> Once the online payment is done successfully, you will get an Acknowledgement Receipt in a new window stating the date and time of submission of documents. </p>
    <p>Kindly print this Acknowledgement Receipt and bring it on the date and time of the submission of documents in the Junior Wing of the School. Please retain the slip to check your ward’s selection status. No selection list will be displayed in the school.</p>
    <p> 14. <strong>In case of a single parent/adopted child, the following documents will have to be furnished:</strong> </p>
    <ul>
        <li> Divorce: Divorce Decree </li>
        <li> Separated: Legal Separation Document </li>
        <li> Widow/Widower: Death Certificate of the spouse </li>
        <li> Adoption: Adoption Decree </li>
    </ul>
    <p> The above legal documents must clearly mention the name of the custodian of the child. </p>
    <p> <strong>Important: </strong> </p>
    <ul>
        <li> Only one form per candidate will be accepted. </li>
        <li> Duplicate forms will be rejected. </li>
        <li> School does not provide any transportation. </li>
        <li> Incase you fail to turn up on the day of verification of documents, you will not be entertained later. </li>
    </ul>
    <p> 15. <strong> Regarding NEP 2020: </strong> The school will not be responsible if any candidate is found underage during any stage of schooling as per the <strong>New Pedagogical & Curricular Structure/Age Policy</strong> according to the NEP 2020. </p>
    
    
    <h4>FEE STRUCTURE OF CLASS LKG FOR ACADEMIC SESSION 2024-25</h4>
    <div className="innertable">
    <table width="100%">
                    <tbody>
                    <tr>
    <th><b> CLASS </b></th>
    <th><b> FEE HEAD </b></th>
    <th><b> AT THE TIME OF ADMISSION ONLY </b></th>
    <th><b> APR - JUN (1st Quarter) </b></th>
    <th><b> JUL - SEP (2nd Quarter) </b></th>
    <th><b> OCT - DEC (3rd Quarter)</b></th>
    <th><b> JAN - MAR (4th Quarter) </b></th>
    </tr>
    
    <tr>
    <td rowspan="8"> LKG </td>
    <td> ADMISSION FEE (NON-REFUNDABLE) </td>
    <td> 5000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> DEVELOPMENT FEE (NON-REFUNDABLE)</td>
    <td> 70000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> SECURITY DEPOSIT (REFUNDABLE) </td>
    <td> 1000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> AMALGAMATED FEE (ANNUAL) </td>
    <td> - </td>
    <td> 4430 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
              
    <tr>
    <td> TUITION FEE </td>
    <td> - </td>
    <td> 15090 </td>
    <td> 15090 </td>
    <td> 15090 </td>
    <td> 15090 </td>
    </tr>
              
    <tr>
    <td> SMART CLASS AND ERP FEE</td>
    <td> - </td>
    <td> 600 </td>
    <td> 600 </td>
    <td> 600 </td>
    <td> 600 </td>
    </tr>
    
    <tr>
    <td>  AIR CONDITIONER - RUNNING & OTHERS FEE  </td>
    <td> - </td>
    <td> 300 </td>
    <td> 300 </td>
    <td> 300 </td>
    <td> 300 </td>
    </tr>          
    <tr>
    <td> <strong>Total</strong></td>
    <td> 76000 </td>
    <td> 20420 </td>
    <td> 15990 </td>
    <td> 15990 </td>
    <td> 15990 </td>
    </tr>
    
                    
                    </tbody></table>
    </div>
    </div>
   <div className="tab-pane fade" id="year2023-24" role="tabpanel" aria-labelledby="year2023-24-tab">
          
    <p><strong>IMPORTANT INFORMATION REGARDING ADMISSION TO LKG – 2023-2024</strong></p>
    <p>1. <strong>Age Eligibility:</strong> The candidate seeking admission to LKG in the year <strong>2023-2024</strong> should be born between <strong>1/1/2019 and 31/12/2019</strong>. The candidate will NOT be eligible for admission if he/she is NOT within the specified age limit.</p>
    <p>2. <strong>Date of Birth Certificate:</strong> The original Date of Birth Certificate issued by the Municipal Corporation/Baptism Certificate (in case of Christian children) along with a photostat copy duly attested by a Class A gazetted officer must be submitted at the time of verification of the documents. <strong>(Note: No Notary attested copy will be accepted)</strong></p>
    <p>3. <strong>Parents’ Qualification Certificates and Aadhar Card Copy:</strong> The original qualification certificates and Aadhar card of both the parents along with a Photostat copy duly attested by a <strong>Class A</strong> gazetted officer must be submitted at the time of verification of the documents. </p>
    <p>4. <strong>Proof of residence of the parents :</strong> Submit a photostat copy of any one of the following documents duly attested by a <strong>Class A</strong> gazetted officer at the time of verification of the documents:</p>
    <p>a) Voter ID card</p>
    <p>b) Electricity Bill</p>
    <p>c) Aadhaar Card</p>
    <p>d) Ration Card</p>
    <p>e) Passport</p>
    <p>f) Rent Deed (If staying on Rent)</p>
    <p><strong>Note:</strong> The original certificate will be returned to the parents immediately after the verification.</p>
    <p>5.<strong> Name and other particulars: </strong> Fill in the particulars of the candidate correctly (i.e. name, parents’ name, date of birth). <strong>The date of birth filled in the form must tally with the one written in the D.O.B Certificate.</strong> All names should be spelt correctly in capital letters as they will be required in all the legal papers and CBSE records. No changes with regard to Date of Birth whatsoever will be entertained once the admission to LKG or any subsequent classes is done.</p>
    <p>6. <strong>Photographs: </strong> The latest photograph (taken not more than a month before the date of application) of the candidate is to be uploaded in the space provided for it. Individual photographs of the parents and a family photograph (showing the parents and the candidate) must be uploaded in the space. All the photographs should be with red background and in JPG format with size less than 20KB. Kindly bring the first two photographs at the time of the verification of documents.</p>
    <p>7. <strong>Selection : </strong> After having set aside, a quota of seats to be filled on the recommendation of the management, the school shall in its sole discretion have the right to grant priority in admission to persons fulfilling the following criteria:</p>
    <ul>
    <li> The children of Staff members of the school be considered for admissions provided other basic criteria for admission to the school are fulfilled.  </li>
    <li>Being a Christian Minority institution, children belonging to that minority community will be considered for admission provided they meet the other requirements of the institution. </li>
    <li> Other applications will be considered for admissions thereafter, by the picking up of lots.</li>
    <li> Once admission has been granted, all formalities including payment of the stipulated
    fees must be completed within the time assigned, failing which it will be deemed that
    the claim for admission has been forfeited.</li>
    <li> The Management of the School reserves all rights of admission or rejection and is not bound to give reasons for admission or rejection of any particular candidate.</li>
    </ul>
    <p>8. <strong>Draw of Lots :  </strong></p>
    <ul>
    <li> Draw of lots to be held online. </li>
    <li> The Registration Number of the candidates whose slip is drawn will be announced during the draw.</li>
    </ul>
    <p>9. <strong>Rejection of Form : </strong>A registration form is liable to be rejected incase of incomplete or incorrect information and no representation will be entertained thereafter</p>
    <p><strong> Caution:<i> The School does not accept any donation for admissions. Parents should  beware of third parties collecting money on behalf of the School and making false claims of procuring admission. If the parents enter into any transaction with such parties, they will be doing so at their own risk and the School shall not be responsible for it. </i></strong></p>
    <p>10. <strong>Regarding Enquiry : </strong>In case you have a query or need any help, kindly email us at <Link to="mailto:contactsaintxaviersbathinda@gmail.com">contactsaintxaviersbathinda@gmail.com</Link> The relevant queries will be replied as soon as possible. </p>
    <p>11. <strong>Registration Fee : </strong>Rs.1000/- is to be paid online and is nonrefundable.</p>
    <p>12. <strong>Fee Structure : </strong>For fee structure, kindly refer to the school website <Link to="www.xavierbathinda.com/fee-rules.aspx"> www.xavierbathinda.com</Link></p>
    <p>13. <strong>Acknowledgement Receipt : </strong>Once the online payment is done successfully, you will   get an Acknowledgement Receipt in a new window stating the date and time of submission of documents.
    Kindly print this Acknowledgement Receipt and bring it on the date and time of the submission of documents in the Junior Wing of the School. Please retain the slip to check your ward’s selection status. No selection list will be displayed in the school.</p>
    <p>14. <strong>In case of a single parent, the following documents will have to be furnished : </strong></p>
    <ul>
    <li>Divorce: Divorce Decree</li>
    <li>Separated: Legal Separation Document</li>
    <li> Widow/ Widower: Death Certificate of the spouse </li>
    </ul>
    <p>The above legal documents must clearly mention the name of the custodian of the child.</p>
    <p><strong>Important</strong></p>
    <ul>
    <li>Only one form per candidate will be accepted.</li>
    <li>Duplicate forms will be rejected.</li>
    <li>School does not provide any transportation.</li>
    <li>Incase you fail to turn up on the day of verification of documents, you will not be entertained later.</li>
    </ul>
    <div className="clr10"></div>
    <div className="clr10"></div>
    <h4>FEE STRUCTURE OF CLASS LKG FOR ACADEMIC SESSION 2023-24</h4>
    <div className="clr10"></div>
    <div className="innertable">
    <table width="100%">
                    <tbody>
                    <tr>
    <th><b> CLASS </b></th>
    <th><b> FEE HEAD </b></th>
    <th><b> AT THE TIME OF ADMISSION ONLY </b></th>
    <th><b> APR - JUN (1st Quarter) </b></th>
    <th><b> JUL - SEP (2nd Quarter) </b></th>
    <th><b> OCT - DEC (3rd Quarter)</b></th>
    <th><b> JAN - MAR (4th Quarter) </b></th>
    </tr>
    
    <tr>
    <td rowspan="7"> LKG </td>
    <td> ADMISSION FEE (NON-REFUNDABLE) </td>
    <td> 4000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> DEVELOPMENT FEE (NON-REFUNDABLE)</td>
    <td> 65000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> SECURITY DEPOSIT (REFUNDABLE) </td>
    <td> 1000 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
    
    <tr>
    <td> ANNUAL FEE </td>
    <td> - </td>
    <td> 4110 </td>
    <td> - </td>
    <td> - </td>
    <td> - </td>
    </tr>
              
    <tr>
    <td> TUITION FEE </td>
    <td> - </td>
    <td> 13980 </td>
    <td> 13980 </td>
    <td> 13980 </td>
    <td> 13980 </td>
    </tr>
              
    <tr>
    <td> SMART CLASS AND ERP </td>
    <td> - </td>
    <td> 600 </td>
    <td> 600 </td>
    <td> 600 </td>
    <td> 600 </td>
    </tr>
              
    <tr>
    <td> <strong>Total</strong></td>
    <td> 70000 </td>
    <td> 18690 </td>
    <td> 14580 </td>
    <td> 14580 </td>
    <td> 14580 </td>
    </tr>
    
                    
                    </tbody></table>
    </div> 
    
    <div className="clr20"></div>
    
   </div>
   <div className="tab-pane fade" id="year2022-23" role="tabpanel" aria-labelledby="year2022-23-tab">
     
      <p><strong>IMPORTANT INFORMATION REGARDING ADMISSION TO LKG – 2022-23</strong></p>
    <p>1. <strong>Age Eligibility:</strong> The candidate seeking admission to LKG in the year 2022-23 should be born between 1/1/2018 and 31/12/2018. The candidate will <strong> NOT</strong> be eligible for admission if he/she is <strong>NOT </strong> within the specified age limit.</p>
    <p>2. <strong>Date of Birth Certificate:</strong> The original Date of Birth Certificate issued by the Municipal Corporation/Baptism Certificate (in case of Christian children) along with a photostat copy duly attested by a <strong> Class A</strong> gazetted officer must be submitted at the time of verification of the documents.<strong> (Note: No Notary attested copy will be accepted). </strong></p>
    <p>3. <strong>Parents’ Qualification Certificates and Aadhar Card Copy:</strong> The original qualification certificates and Aadhar card of both the parents along with a Photostat copy duly attested by a <strong> Class A</strong> gazetted officer must be submitted at the time of verification of the documents. </p>
    <p>4. <strong>Proof of residence of the parents :</strong> Submit a photostat copy of any one of the following documents duly attested by a <strong> Class A</strong> gazetted officer at the time of verification of the documents:</p>
    <p><strong>a) Voter ID card</strong></p>
    <p><strong>b) Electricity Bill</strong></p>
    <p><strong>c) Aadhaar Card</strong></p>
    <p><strong>d) Ration Card</strong></p>
    <p><strong>e) Passport</strong></p>
    <p><strong>f) Rent Deed (If staying on Rent)</strong></p>
    <p><strong>Note: The original certificate will be returned to the parents immediately after   The verification.</strong></p>
    <p>5.<strong> Name and other particulars: </strong> Fill in the particulars of the candidate correctly (i.e. name, parents’ name, date of birth). <strong>The date of birth filled in the form must tally with the one written in the D.O.B Certificate.</strong>  All names should be spelt correctly in capital letters as they will be required in all the legal papers and <strong>CBSE</strong>  records. No changes with regard to Date of Birth whatsoever will be entertained once the admission to LKG or any subsequent classes are done.</p>
    
    <p>6. <strong>Photographs: </strong>The latest photograph (taken not more than a month before the date of application) of the candidate is to be uploaded in the space provided for it. A family photograph (showing the parents and the candidate) must be uploaded in the space. Kindly bring the same photographs at the time of the verification of documents.</p>
    <p>7. <strong>Selection : </strong> After having set aside, a quota of seats to be filled on the recommendation of the management, the school shall in its sole discretion have the right to grant priority in admission to persons fulfilling the following criteria:</p>
    <ul>
    <li>The children of Staff members of the school be considered for admissions provided other basic criteria for admission to the school are fulfilled.  </li>
    <li>Being a Christian Minority institution, children belonging to that minority community will be considered for admission provided they meet the other requirements of the institution. </li>
    <li>Other applications will be considered for admissions thereafter, by the picking up of  lots. </li>
    <li>Once admission has been granted, all formalities including payment of the      stipulated fees must be completed within the time assigned, failing which it will be deemed that   the claim for admission has been forfeited. </li>
    <li>The Management of the School reserves all rights of admission or rejection and is    not bound to give reasons for admission or rejection of any particular candidate.</li>
    </ul>
    <p>8. <strong>Draw of Lots :  </strong></p>
    <ul>
    <li>Due to Pandemic constraints, this Draw of Lots will be done in such manner and by following such procedure as the management of the school may decide.  </li>
    <li>The Registration Number of the candidates whose slip is drawn will be announced during the draw.</li>
    </ul>
    <p>9. <strong>Rejection of Form : </strong>A registration form is liable to be rejected in case of incomplete or incorrect information and no representation will be entertained thereafter.</p>
    <p><strong> Caution:<i> The School does not accept any donation for admissions. Parents should  beaware of third parties collecting money on behalf of the School and making false claims of procuring admission. If the parents enter into any transaction with such parties, they will be doing so at their own risk and the School shall not be responsible for it.
     </i></strong></p>
    <p>10. <strong>Regarding Enquiry : </strong>In case you have a query or need any help, kindly email us at <Link to="mailto:contactsaintxaviersbathinda@gmail.com">contactsaintxaviersbathinda@gmail.com</Link> The relevant queries will be replied as soon as possible. </p>
    <p>11. <strong>Registration Fee : </strong>Rs.1000/- is to be paid online and is nonrefundable.</p>
    <p>12. <strong>Fee Structure : </strong>For fee structure, kindly refer to the school website <Link to=" www.xavierbathinda.com"> www.xavierbathinda.com</Link></p>
    <p>13. <strong>Acknowledgement Receipt : </strong>Once the online payment is done successfully, you will   get an Acknowledgement Receipt in a new window stating the date and time of submission of documents.
          Kindly print this Acknowledgement Receipt and bring it on the date and time of the submission of documents in the Junior Wing of the School. Please retain the slip to check your ward’s selection status. No selection list will be displayed in the school.</p>
    <p>14. <strong>In case of a single parent, the following documents will have to be furnished : </strong></p>
    <ul>
    <li>Divorce: Divorce Decree</li>
    <li>Separated: Legal Separation Document</li>
    <li> Widow/ Widower: Death Certificate of the spouse
    The above legal documents must clearly mention the name of the custodian of the child. </li>
    </ul>
    <p><strong>Important</strong></p>
    <ul>
    <li>Only one form per candidate will be accepted.</li>
    <li>Duplicate forms will be rejected.</li>
    <li>School does not provide any transportation.</li>
    <li>Incase you fail to turn up on the day of verification of documents, you will not be entertained later.</li>
    </ul>
    <div className="clr10"></div>
    <div className="clr10"></div>
    <div className="clr10"></div>
    <h4>FEE STRUCTURE OF CLASS LKG FOR ACADEMIC SESSION 2022-23.</h4>
    <div className="clr10"></div>
    <div className="innertable">
    <table width="100%">
    <tr>
    <th>CLASS</th>
    <th>FEE HEAD</th>
    <th>AT THE TIME OF ADMISSION ONLY</th>
    <th>APR-JUN (1st Quarter)</th>
    <th>JUL-SEP (2nd Quarter)</th>
    <th>OCT-DEC (3rd Quarter)</th>
    <th>JAN-MAR (4th Quarter)</th>
    </tr>
    <tbody>
    <tr>
    <td rowspan="7">LKG</td>
    <td>ADMISSION FEE      (Non-Refundable)</td>
    <td>4000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>DEVELOPMENT FEE (Non-Refundable)</td>
    <td>65000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>SECURITY DEPOSIT (Refundable)</td>
    <td>1000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>AMALGAMATED FEE</td>
    <td>-</td>
    <td>3810</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>TUITION FEE</td>
    <td>-</td>
    <td>12960</td>
    <td>12960</td>
    <td>12960</td>
    <td>12960</td>
    
    </tr>
    <tr>
    <td>SMART CLASS AND ERP</td>
    <td>-</td>
    <td>600</td>
    <td>600</td>
    <td>600</td>
    <td>600</td>
    </tr>
    <tr>
    <td>TOTAL</td>
    <td>70000</td>
    <td>17370</td>
    <td>13560</td>
    <td>13560</td>
    <td>13560</td>
    </tr>
    
    </tbody></table>
    </div>
    
    <div className="clr20"></div>
    
    
   </div>
   <div className="tab-pane fade" id="year2021-22" role="tabpanel" aria-labelledby="year2021-22-tab"> 
        <p><strong>Availability of Forms: </strong>Online LKG Registration Forms will be available from 17th November, 2020 09:00 a.m. and will close on 24th November 2020, 05:00 p.m.</p>
    <p><strong>Important: </strong>Username/ Password will be automatically generated only after complete submission of the online form and will not be required before the submission.
    This username and password is required only to check your Ward’s selection status. It will not enable you to edit or make any changes in the form after final submission.</p>
    <p><strong>Pre-requisites: </strong> Ensure that you have the following before you begin to fill the form - Photograph of the child (Jpg format to be uploaded)
    - Family photograph with the child (Jpg format to be uploaded) - Size of each photographs should not exceed 20 KB.
    - Valid Email ID</p>
    <div className="clr10"></div>
    <h4>IMPORTANT INFORMATION REGARDING ADMISSION TO LKG – 2021-22</h4>
    <div className="clr10"></div>
    <p><strong>1. Age: </strong>The child seeking admission to LKG in the year 202 l should be born between 01/01/2017 and 31/12/2017. If your child is NOT within the specified date limits, she/he will NOT be eligible for admission.</p>
    <p><strong>2. Date of Birth Certificate:</strong> The original Date of Birth Certificate issued by the Municipal Corporation/Baptism Certificate (in case of Christian children) along with a photo copy duly attested by a Class A gazetted officer must be submitted at the time of verification of the forms.</p>
    <p><strong>3. Proof of residence of the parents: </strong>Submit a photo copy of any one of the following documents duly attested by a gazetted officer at the time of verification of the documents:</p>
    <p><strong>a) Voter ID card</strong></p>
    <p><strong>b) Electricity Bill</strong></p>
    <p><strong>c) Aadhaar Card</strong></p>
    <p><strong>d) Rent Deed (If staying on Rent)</strong></p>
    <p><strong>4. Name and other particulars: </strong>Fill in the particulars of the child correctly (i.e. name, parents’ name, date of birth). The date of birth filled in the form must tally with the one written in the D.O.B Certificate. All names should be spelt correctly in capital letters as they will be required in all the legal papers and CBSE records. No changes with regard to Date of Birth whatsoever will be entertained once the admission to LKG or any subsequent classes are done.</p>
    <p><strong>5. Photographs: </strong>The latest photograph (taken not more than a month before the date of application) of the child is to be uploaded in the space provided for it. A family photograph (showing the parents and the child) must be uploaded in the space. Kindly bring the same photographs at the time of the Verification of Documents.</p>
    <p><strong>6. Selection:</strong> After having set aside a quota of seats for the management as per the discretion of the management, the following provisions will be followed:</p>
    <ul>
    <li>i) The children of Staff members of the school will be given admissions provided other basic criteria for admission to the school are fulfilled.</li>
    <li>ii) Siblings of the applied candidates studying in St. Xavier’s School, Bathinda who have scored above 75%, will be given priority for admission. Kindly submit:</li>
    <p>a) A photo copy of Final Term Examination Session 2019-20</p>
    <p>b) A photo copy of fee booklet of session 2020-21</p>
    </ul>
    <ul>
    <li>iii) Being a Christian Minority institution, children belonging to that minority community will be given admission provided they meet the other requirements of the institution.</li>
    <li>iv) Other admissions will be decided by the picking up of lots.</li>
    <li>v) Once admission has been granted, all formalities including payment of the stipulated fees must be completed within the time assigned, failing which it will be deemed that the claim for admission has been forfeited.</li>
    </ul>
    
    <p><strong>7. Rejection of Forms: </strong>Incomplete registration forms and forms providing incorrect information will automatically stand rejected.</p>
    <p><strong>8. Caution: </strong><i>The School does not accept any donation for admissions. Parents should be aware of third parties collecting money on behalf of the School and making false claims of procuring admission. If the parents enter into any transaction with such parties, they will be doing so at their own risk and the School shall not be responsible for it.</i></p>
    <p><strong>9. Regarding Enquiry: </strong>In case you have a query or need any help, kindly email us at contactsaintxaviersbathinda@gmail.com. The relevant queries will be replied as soon as possible.</p>
    <p><strong>10. Registration Fee: </strong>Rs. 1000/ - is to be paid online and is non refundable.</p>
    <p><strong>11. Acknowledgement Receipt:</strong> Once the online payment is done successfully, you will get an Acknowledgement Receipt in a new window stating the date and time of submission of documents.
    Kindly print this Acknowledgement Receipt and bring it on the date and time of the submission of documents in the Junior Wing of the School. Please retain the slip to check your Wards selection status. No Selection list will be displayed in the school.</p>
    <p><strong>12. Incase of a single parent, the following documents will have to be furnished:</strong><br/></p>
    <div style={{marginLeft:"10px"}}> <p>Divorce: Divorce Decree<br/>
    -Separated: Legal Separation Document<br/>
    -Widow/ Widower: Death Certificate of the spouse<br/></p>-
    </div><p>
    The above legal documents must clearly mention the name of the custodian of the child.
    </p>
    <p><strong>Important: </strong>You are advised to fill in only ONE online form. Duplicate Forms shall be rejected. Please note that the school does not provide any transportation.</p>
    <div className="clr10"></div>
    <h4>Fee Structure of Class LKG for the Academic Session (2021 - 2022)</h4>
    <div className="clr10"></div>
    <div className="innertable">
    <table width="100%">
    <tbody><tr>
    <th>CLASS</th>
    <th>FEE HEAD</th>
    <th>ADM FEES</th>
    <th>APR-JUN</th>
    <th>JUL-SEP</th>
    <th>OCT-DEC</th>
    <th>JAN-MAR</th>
    </tr>
    <tr>
    <td rowspan="7">LKG</td>
    <td>ADMISSION FEE</td>
    <td>4000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>CAUTION MONEY DEPOSIT</td>
    <td>1000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>AMALGAMATED FEE</td>
    <td>-</td>
    <td>3530</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>TUITION FEE</td>
    <td>-</td>
    <td>12000</td>
    <td>12000</td>
    <td>12000</td>
    <td>12000</td>
    </tr>
    <tr>
    <td>SMART CLASS AND ERP</td>
    <td>-</td>
    <td>600</td>
    <td>600</td>
    <td>600</td>
    <td>600</td>
    
    </tr>
    <tr>
    <td>DEVELOPMENT ACT</td>
    <td>65000</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    </tr>
    <tr>
    <td>TOTAL</td>
    <td>70000</td>
    <td>16130</td>
    <td>12600</td>
    <td>12600</td>
    <td>12600</td>
    </tr>
    
    </tbody></table>
    </div>
    
   </div>
 </div>
   
   <div className="clr"></div>
   </div>

<ProspectSidebar/>

</div>
</div>
  <Footer/>
  </>
  )
}

export default ProcessAdmission
