import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
const Alumni = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Alumni</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Alumni</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-12 col-md-12 col-sm-12 col-12 message_inner">
     <p><b>Dear Alumni,</b></p>
     <ul>
     <li> Life Time Registration Fee for Alumni is Rs. 500/-.</li> 
     <li> If Registered, kindly log in with your username and password to register for 1st Alumni Meet. The Amount is Rs. 1500 /- per family.</li> 
     <li> 1st Alumni meet is on 21st January 2023 in School Campus for batches 1994 to 2010.</li> 
     <li> For any query related to alumni meet, Call or Whatsapp 9592000004</li> 
     </ul>
     
     <p><b>Manpreet Singh,</b><br />
     (President  Xavier's Alumni)</p>
     
     <p><a href="https://xavierbathinda.campuscare.info/logon/alumniregistration" target="_blank"> Click to Alumni Registration </a> </p>
     <p><a href="https://xavierbathinda.campuscare.info/" target="_blank"> Click to Alumni Login </a> </p>
     <div className="clr"></div>
         <p><strong>-------- OR --------</strong></p>
         <p><strong>Details for making direct online payment are as follows-</strong></p>
     <p>Bank Name - Bank of Baroda <br/>
     Branch - Near Bus Stand, Bathinda <br/>
     Account Holder - Society of Pilar, Punjab and Haryana <br/>
     Account No. - 09780200008290 <br/>
     IFSC Code - BARB0BHATIN</p>
     <div className="clr"></div>
     <p>Alumni Registration + Single Entry = Rs. 1300</p>
     <p>Alumni Registration + Family Entry = Rs. 2000</p>
     <p>*Please mention your Name, Contact No. and Batch Year while making the direct bank payments.</p>
     <div className="clr"></div>
     </div>
     </div>
     
     </div>
   <Footer/>
  </>
  )
}

export default Alumni
