import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getHighlight } from '../Service/Api';

const Highlights = () => {
  const marqueeRef = useRef(null);
  const [yearRanges, setYearRanges] = useState([]);

  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };

  const handleMouseOut = () => {
    marqueeRef.current.start();
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        const galleryData = await getHighlight();
        console.log(data)

        // Sort data in descending order by date to show the latest data first
        galleryData.sort((a, b) => {
            const dateA = new Date(a.fromDate);
            const dateB = new Date(b.fromDate);
            return dateB - dateA; // Descending order
        });

        setData(galleryData);

        const uniqueYears = Array.from(
            new Set(galleryData.flatMap((item) => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    return [year];
                } else {
                    return [];
                }
            }))
        ).sort((a, b) => b - a);

        setYearRanges(uniqueYears);
    };

    fetchData();
}, []);
  const emptyArray = [
    { description: "Stay tuned for latest updates" },
    { description: "Stay tuned for latest updates" },
    { description: "Stay tuned for latest updates" },
  ];

  return (
    <>
      <marquee
        scrollamount="2"
        scrolldelay="30"
        direction="up"
        align="left"
        ref={marqueeRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {data.length > 0 ? (
          data
            .filter(
              (item) =>
                (item.school === "Senior" || item.school === "Both") &&
                item.category === "Highlight"
            )
            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by latest date first
            .slice(0, 10) // Limit to first 10 items
            .map((item, index) => (
              <div className="card p-2" key={index}>
                <div className="row g-0">
                  <div className="card-body">
                    {item.new==='true' &&  <img
                      src="/Images/new.gif"
                      className="new_menu_link_news"
                      alt="New"
                    />}
                  
                    <h5>{item.title}</h5>
                    <p>{item.url && <Link to={item.url}>Click Here</Link>}</p>
                    <p>{item.description}</p>
                    {item.attachments && item.attachments.length > 0 && (
                      <p className="card-text">
                        <Link
                          to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}
                          target="_blank"
                        >
                          <small className="text-muted">
                            <i className="bi bi-file-earmark-text-fill"></i> View
                            Attachment
                          </small>
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))
        ) : (
          emptyArray.slice(0, 10).map((item, index) => (
            <div className="card p-2" key={index}>
              <div className="row g-0">
                <div className="card-body">
                  <img
                    src="/Images/new.gif"
                    className="new_menu_link_news"
                    alt="New"
                  />
                  <h5>Notice</h5>
                  <p>
                    -<Link to="#"> Click Here</Link>
                  </p>
                  <p>{item.description}</p>
                  <p className="card-text">
                    <Link to="#" target="_blank">
                      <small className="text-muted">
                        <i className="bi bi-file-earmark-text-fill"></i> View
                        Attachment
                      </small>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </marquee>
    </>
  );
};

export default Highlights;
