import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom' 
import Slider from "react-slick";
import { getNews } from '../Service/Api';
const News = () => {
  var settings = {  
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow:3,
    slidesToScroll: 1,
    initialSlide: 0, 
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
      
        {
          breakpoint: 1300,
          settings: {
            slidesToShow:2, 
          }
        },
        {
            breakpoint: 1070,
            settings: {
              slidesToShow: 2, 
            }
          },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2, 
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:2, 
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:1, 
          }
        }
      ]
  };

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNews();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" }
  ];

  return (
    <>
    <div className="row">
    <Slider {...settings}>   
         
    {newsData.length > 0 ?  newsData?.filter((item) => item.school === "Senior" || item.school === "Both" && item.category === "News").map((item, index) => (            
          <div className="card newscard" key={index}>
          <div className="backgroundEffect"></div>
          <div className="pic"><img src={item.image ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image}` : "/Images/news.jpg"} className="card-img-top" alt="St. Xaviers Bathinda"/></div>

          <div className="date"> <Link target="_blank" to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}><img src="/Images/attachment-icon.png" alt="St. Xaviers Bathinda"/></Link> </div>
          <div className="card-body">
          <h5 className="card-title">{item.title}</h5>
          <p className="card-text">{item.description}</p>
          </div>
          <div className="card-footer">
          <div className="d-flex align-items-center justify-content-center foot">
          </div>
          </div>
          </div>
          )) : 
          emptyArray.map((data, index) => (
            <div className="card newscard" key={index}>
            <div className="backgroundEffect"></div>
            <div className="pic"><img src="/Images/news.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
            <div className="date"> <Link target="_blank" to="#"><img src="/Images/attachment-icon.png" alt="St. Xaviers Bathinda"/></Link> </div>
            <div className="card-body">
            <h5 className="card-title">{data.title}</h5>
            <p className="card-text">{data.description}</p>
            </div>
            <div className="card-footer">
            <div className="d-flex align-items-center justify-content-center foot">
            </div>
            </div>
            </div>
          ))
          }
         
{/*            
              <div className="card newscard">
                <div className="backgroundEffect"></div>
                <div className="pic"><img src="/Images/news.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
                <div className="date"> <Link target="_blank" to="#"><img src="/Images/attachment-icon.png" alt="St. Xaviers Bathinda"/></Link> </div>
                <div className="card-body">
                  <h5 className="card-title">News Heading - 1</h5>
                  <p className="card-text">Stay tuned for more information about latest updates. Stay tuned for more information about latest updates</p>
                </div>
                <div className="card-footer">
                  <div className="d-flex align-items-center justify-content-center foot">
    
                  </div>
                </div>
              </div>
        
              <div className="card newscard">
                <div className="backgroundEffect"></div>
                <div className="pic"><img src="/Images/news.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
                <div className="date"> <Link target="_blank" to="#"><img src="/Images/attachment-icon.png" alt="St. Xaviers Bathinda"/></Link> </div>
                <div className="card-body">
                  <h5 className="card-title">News Heading - 1</h5>
                  <p className="card-text">Stay tuned for more information about latest updates. Stay tuned for more information about latest updates</p>
                </div>
                <div className="card-footer">
                  <div className="d-flex align-items-center justify-content-center foot">
    
                  </div>
                </div>
              </div>
              <div className="card newscard">
              <div className="backgroundEffect"></div>
              <div className="pic"><img src="/Images/news.jpg" className="card-img-top" alt="St. Xaviers Bathinda" /></div>
              <div className="date"> <Link target="_blank" to="#"><img src="/Images/attachment-icon.png" alt="St. Xaviers Bathinda"/></Link> </div>
              <div className="card-body">
                <h5 className="card-title">News Heading - 1</h5>
                <p className="card-text">Stay tuned for more information about latest updates. Stay tuned for more information about latest updates</p>
              </div>
              <div className="card-footer">
                <div className="d-flex align-items-center justify-content-center foot">
  
                </div>
              </div>
            </div> */}

           </Slider>
          </div>
    </>
  )
}

export default News
