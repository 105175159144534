import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import AboutSidebar from'./AboutSidebar'
import { Link } from 'react-router-dom'
const Vision = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Vision</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> About Us <i className="bi bi-arrow-right"></i> Vision</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12">
<p>The St. Xavier's School Bathinda is an institution run by the Society of Pilar and was founded with the dream, objective and aim of extending high quality education and integral development to children without distinction of Caste, Creed, Race or Religion. St. Xavier's School, Bathinda was opened in 1983, as a response to the pressing educational needs of the town of Bathinda and the surrounding villages. The school is a minority Christian Institution and has it's motto "love your neighbour" indicates its vision that is to create conditions through which honest and upright citizens will be formed for our beloved Country with core values of brotherhood, harmony, universal love, and attitudes of kindness and compassion. The vision also includes enabling integral human development of each child in which academic and educational excellence are matched by physical dexterity, spiritual enlightenment, and emotional maturity.</p>

<div className="clr"></div>
</div>

<AboutSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default Vision
