import React from 'react'
import { NavLink } from 'react-router-dom'
const ProspectSidebar = () => {
  return (
   <>
   <div className="col-lg-3 col-md-4 col-sm-12 col-12">
   <div className="inner_menu">
   <h3>Menu Choice</h3>
   <ul id="sidebar">
   <li> <NavLink id="A1" to="/Curriculum">Curriculum</NavLink> </li>
   <li><NavLink id="A2" to="/SchoolTimings">School Timings</NavLink></li>
   <li><NavLink id="A2" to="/SchoolUniform">School Uniform</NavLink></li>
   <li><NavLink id="A3" to="/ParentCooperation">Parent's Co-Operation</NavLink></li>
   <li><NavLink id="A4" to="/AdmissionWithdrawal">Admission &amp; Withdrawal</NavLink></li>
   <li> <NavLink id="A6" to="/AdmissionRules">Admission Rules</NavLink> </li>
   <li><NavLink id="A7" to="/ProcessAdmission">Process of Admission for LKG</NavLink></li>
   <li><NavLink id="A8" to="/FeeRules">Fee Rules & Structure</NavLink> </li>
   <li><NavLink id="A9" to="/WithdrawalDismissal">Withdrawal &amp; Dismissal Rules</NavLink></li>
   <li> <NavLink id="A10" to="/AssessmentRules">Assessment Rules</NavLink> </li>
   <li> <NavLink id="A12" to="/LibraryRules">Library Rules</NavLink></li>
   <li> <NavLink id="A13" to="/Attendance">Attendance Rules</NavLink></li>
   <li> <NavLink id="A14" to="/Tc">Transfer Certificate</NavLink></li>
   <li> <NavLink id="A15" to="/pdf/Declaration.pdf" target="_blank">Declaration </NavLink></li>
   <li> <NavLink id="A16" to="/BookList">Book Lists</NavLink></li>
   </ul>
   <div className="clr"></div>
   </div>
   <div className="important_menu">
   <h3>Important Menu</h3>
     <ul>
       <li><NavLink to="/VirtualTour"><i className="fas fa-camera" aria-hidden="true"></i> 360<sup>o</sup></NavLink></li>
       <li><NavLink to="/ParentPortal"><i className="fa fa-newspaper" aria-hidden="true"></i> Portal Help?</NavLink></li>
       <li><NavLink to="/ErpSoftware"><i className="fas fa-desktop" aria-hidden="true"></i> ERP Software</NavLink></li>
       <li><NavLink to="/Junior/Default"><i className="fas fa-child" aria-hidden="true"></i> Kid's Corner</NavLink></li>
       <li><NavLink to="/Achievements"><i className="fa fa-trophy" aria-hidden="true"></i> Achievements</NavLink></li>
       <li><NavLink to="/Speech"><i className="fa fa-microphone" aria-hidden="true"></i> Speech</NavLink></li>
       </ul>
   <div className="clr"></div>
   </div>
   
   </div>
   </>
  )
}

export default ProspectSidebar
