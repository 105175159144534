import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from'../Component/Footer' 
import { getSubGallery } from '../../Service/Api';
const JuniorPhotoCategory = () => {
    const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
  }));

  return (
   <>
   <div className="kids">
   <div className="container juniorcontainer">
   <div className="row">
   <div className="col-lg-6 col-md-6 col-12">
   <Link to="/JuniorHome"><img src="/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></Link>

   </div>
   <div className="col-lg-6 col-md-6 col-12">
    <Link to="/JuniorHome"> <img src="/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></Link>
   <div className="kintergarten_slider_sec_btm_bird"><img src="/JuniorImages/bird.gif" className="img-fluid"/></div>
   </div>
   </div>
   <div className="kids-back-sec">
   <h2>Photo Category</h2>
   
   <div className="news-inner">
   <div className="row tabs-dynamic">
   <div className="col-md-3 col-xl-3">
              <div className="count-val">
                {/* Use navigate function to programmatically navigate */}
                <p>
                  <a href='' onClick={() => navigate("/JuniorPhotoGallery")}>
                    <span id="lblcount">
                      <i className="bi bi-arrow-left"></i>
                    </span>{" "}
                    Go Back
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-3 col-xl-3">
              <div className="month-selection">
                <p>
                  <span>Event Date:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
              </div>
            </div>
            <div className="col-md-3 col-xl-3">
              <div className="month-selection">
                <p>
                  <span>Last Update:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
              </div>
            </div>
            <div className="col-md-3 col-xl-3">
              <div className="searchBox">
                <p>
                  <span>No. Of Photos:</span> {data?.attachments?.length}
                </p>
              </div>
            </div>
          </div>
 <div className="row">
   <div className="col-md-12">
       <h4 className="gallery-title">{data.title}</h4>
       <p>{data.description} </p>
   </div>
   {data !== "" &&
              data?.attachments.map((img,index) => (
       <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2 galleryCount sub-gallerycount">
            <div className="gallery-blk">
               <a data-magnify="gallery" data-src="" data-group="a" href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`}><img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} class="img-fluid" alt="" /></a> 
                
                
            </div>
        </div>
 ))}
  {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
       <div className="gallery-blk">
           
           <a data-magnify="gallery" data-src="" data-group="a" href="/JuniorImages/common-img-4.jpg"><img src="/JuniorImages/common-img-4.jpg" className="img-fluid" alt="St. Xaviers Bathinda"/></a>
           
       </div>
   </div> */}
</div> 

   </div>
   
   </div>
   </div>
   </div>
   <Footer/>
   </>
  )
}

export default JuniorPhotoCategory
