import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getPrincipalMessage } from '../Service/Api';
const Message = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await getPrincipalMessage();
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log('events_data', data);
  }, [data]);

  return (
     <>
     <div className="message-sec mt-5 span3 wow bounceInDown center" data-wow-delay="1s">
     <div className="container">
       <div className="row">
         <div className="col-12 col-lg-12 col-xl-8">
           <div className="row">
             <div className="col-12 col-xs-12 col-md-6">
               <div className="row"><Link to="PresidentMessage"><img src="/Images/right-arrow.png" className="img-fluid right-arrow" alt="St. Xaviers Bathinda" /> <img src="/Images/president.jpg" className="img-fluid" alt="St. Xaviers Bathinda" /> </Link></div>
             </div>
             <div className="col-12 col-xs-12 col-md-6">
               <div className="row message-sec-bg p-4">
                 <h1>President's <span> Message</span></h1>
                 <p>As we navigate the journey of life, it's essential to reflect on the values that shape our character and guide our actions. Allow me to share with you timeless words of wisdom discovered on the walls of an orphanage in Calcutta, India:<br />"If you are kind, people may accuse you of selfish, ulterior motives. Be kind anyway." <br />In a world where cynicism can cloud our intentions, let kindness be our compass. Despite the skeptics, let our actions be driven by genuine empathy and compassion.<br />"When you spend years building, someone could destroy overnight. Build anyway."</p>
               </div>
             </div>
            
             <div className="clearfix"></div>
             
             <div className="col-12 col-xs-12 col-md-6">
               <div className="row message-sec-bg2 p-4">
                 <h1>Manager's <span>Message</span></h1>
                  <p>‘Give a man a fish and you feed him for a day; teach a man to fish and you feed him for a lifetime.’ Teaching a person, a useful skill can be more beneficial in the long run than filling a need for them temporarily, that’s basically the meaning of this proverb. It focuses on the idea that long-term benefits are more useful than short-term benefits. I firmly believe that an educational institute is not just about infrastructure but about building persons’ character, and enlightening minds that will lead them toward lifetime growth. Doing and learning, analyzing, and applying is the elegance of St. Xavier’s School. We look forward to integrated teaching and learning as per NEP 2020. We are striving to provide our students with an atmosphere for multifaceted development where every child is encouraged to channelize their potential in the pursuit of excellence with a friendly environment, warmth, and individual care that will undoubtedly tap the inherent potential that exists in every individual.</p>
               <img src="/Images/right-arrow.png" className="img-fluid right-arrow" alt="St. Xaviers Bathinda" />
               </div>
             </div>
             <div className="col-12 col-xs-12 col-md-6">
               <div className="row"><Link to="ManagerMessage">  <img src="/Images/manager.jpg" className="img-fluid" alt="St. Xaviers Bathinda" /></Link> </div>
             </div>
           </div>
         </div>
         <div className="col-12 col-lg-12  col-xl-4">
           <div className="row">
             <div className="col-12 col-sm-12 col-md-6 col-xl-12">
               <div className="row"> 
                <Link to="principalMessage"> <img src="/Images/right-arrow-2.png" className="img-fluid right-arrow-2" alt="St. Xaviers Bathinda" /> </Link>   <Link to="#"><img src="/Images/principal.JPG" className="img-fluid principal-img" alt="St. Xaviers Bathinda" /> </Link>
               </div>
             </div>
               <div className="col-12 col-sm-12 col-md-6 col-xl-12">
                   <div className="row principal-msg p-4">
                     <h1>Principal's <span>Message</span></h1>
                     {data.length > 0 &&
  data
    .filter((div) => div.category === "Principal")
    .map((item, index) => (
      <p key={index}>{item.message}</p>
    ))
}

                     </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     </>
  )
}

export default Message
