import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
const Luminaire = () => {
   return (
    <>
<Header/>   <div className="inner_slider">
   <img src="Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
   <div className="row bread_cumb">
   <h2>Luminaire</h2>
   <div className="theme-heading">
   <span className="colorW marB10"><i className="bi bi-building"></i></span>
   </div>
   <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Events <i className="bi bi-arrow-right"></i></span> <span> Luminaire </span></h5>
   </div>
   
   </div>
   <div className="innerpage lumi_img">
<div className="container inner_cont">
   <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
   <li className="nav-item" role="presentation">
   <button className="nav-link active" id="pills-Four-tab" data-bs-toggle="pill" data-bs-target="#pills-Four" type="button" role="tab" aria-controls="pills-Four" aria-selected="true">2024 </button>
</li>
    <li className="nav-item" role="presentation">
     <button className="nav-link" id="pills-Three-tab" data-bs-toggle="pill" data-bs-target="#pills-Three" type="button" role="tab" aria-controls="pills-Three" aria-selected="true">2023 </button>
  </li>
   <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-Two-tab" data-bs-toggle="pill" data-bs-target="#pills-Two" type="button" role="tab" aria-controls="pills-Two" aria-selected="true">2022 </button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true">2021</button>
  </li>
</ul>
<div className="tab-content" id="pills-tabContent">
<div className="tab-pane fade show active" id="pills-Four" role="tabpanel" aria-labelledby="pills-Four-tab">
<div className="row">
 <div className="col-md-12">
 <h3>St. Xavier’s Luminaire, 2024-25</h3>
 <p><b>Interschool Competition <br/>
 LKG – Class V <br/></b></p>

 <img src="Images/event-logo24.jpg" className="img-fluid mx-auto d-block event-logo"/>
 <p><b> 'St. Xavier's Luminaire, 2024-25 -  Salvaging Dreams'</b></p>
<p>Honouring and appreciating the talent of students from different schools with accolades, a two-day interschool competition ‘Luminaire-2024-25’ was held with great zeal and enthusiasm on 12th and 13th August, 2024 in the campus. The event got off to a luminous start with the lamp lighting officiated by the chief guest under the supervision of Rev. Fr. Amaro Martins, Manager, Rev. Fr. Cidloy Furtado, Principal and Ms. Pallavi Jain, the coordinator. Commencing on a devotional note, with a verse from the holy Bible followed by the prayer, the opening ceremony embraced various facets of society and culture.</p>


<p>The function included a series of thematic performances ranging from devotion to moral values, including social issues and many more. Mr Anil Thakur, Chairman, Punjab State Traders Commission presided as the chief guest on the opening ceremony. Mr Neel Garg, Chairman, Medium Industry Development Board, Govt of Punjab graced the occasion as guest of honour. The chief guest for day 2 was Mr. Kushal Mittal, Joint Managing Director at BCL Industries Ltd. The most memorable moment of the function was marked by precious words of advice and appreciation by the chief guest during his address. Thereafter, he distributed the prizes to the winners.</p>

<p>Overall, the event concluded on a happy note encouraging the students to establish further milestones in the journey of success.</p>
<p> Winning School Trophy was bagged by- St. Joseph's High School, Mandi Dabwali</p>

 </div>
 <br/>
 <div className="col-md-12">
 <h3>Schools Participating </h3>
 <p><strong>St. Xavier's Luminaire, 2024-25</strong></p>
 <div className="innertable">
 <table width="100%">
 <tbody><tr>
 <th>Sr.No</th>
 <th>Name of school</th>
 <th>City</th>
 </tr>
    <tr>
        <td>1</td>
        <td>St. Xavier’s School, Bathinda</td>
        <td>Bathinda</td>
    </tr>
    <tr>
        <td>2</td>
        <td>Rose Mary Convent School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>3</td>
        <td>Shivalik Public School</td>
        <td>Jaitu</td>
    </tr>
    <tr>
        <td>4</td>
        <td>St. Joseph&#39;s Convent Punjabi High School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>5</td>
        <td>St. Joseph&#39;s Convent School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>6</td>
        <td>Sri Guru Hargobind Sen.Sec. School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>7</td>
        <td>Sri Guru Harkrishan Public School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>8</td>
        <td>Global Discovery School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>9</td>
        <td>Iconic International School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>10</td>
        <td>Delhi Public School Junior Wing City Branch</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>11</td>
        <td>The Millennium School </td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>12</td>
        <td>Delhi Public School </td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>13</td>
        <td>St.Kabir Convent Sen.Sec.School </td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>14</td>
        <td>S.S.D. Krishna Vatika Sen. Sec. School</td>
        <td>Bathinda </td>
    </tr>
    <tr>
        <td>15</td>
        <td>Army Public School </td>
        <td>Bathinda Cantt. </td>
    </tr>
    <tr>
        <td>16</td>
        <td>St. Joseph&#39;s High School</td>
        <td>Mandi Dabwali</td>
    </tr>
    <tr>
        <td>17</td>
        <td>Star Plus Convent School</td>
        <td>Raman </td>
    </tr>
    <tr>
        <td>18</td>
        <td>The Oxford Public School</td>
        <td>Cheema</td>
    </tr>
 </tbody></table>
 </div>



 </div>
 <br/>
 <div className="row">
 <h3>Events List </h3>
 <div className="col-md-6">
 <p><strong>Events Day 1</strong></p>
 <ol>
 <li> Trashion Show (Lower Kindergarten/3-4 years) </li>
 <li> Verse Voices (Upper Kindergarten/ 4-5 years) </li>
 <li> Freedom Rangoli (1/5-6 years) </li>
 <li>  Mythical Mash Up (II/6-7 Years) </li>
 <li> My Future Saviour (III/7-8years) </li>
 <li>  Visual Vibes (IV/ 8-9 years) </li>
 <li>  Code Champs (V/9-10 years) </li>
 <li>  United We Dance </li>

 </ol>
 </div>
 <div className="col-md-6">
 <p><strong>Events Day 2</strong></p>
 <ol>
    
 <li>Fairy Fitness (Lower Kindergarten/3-4 years) </li>
 <li>Tricolour Nature Hunt (Upper Kindergarten/4-5 years) </li>
 <li> Misty Fun (1/5-6 years) </li>
 <li> Matchbox Race (II/6-7 Years) </li>
 <li> My Parachute (III/7-8 Years) </li>
 <li> Blind Fold Maze (IV/ 8-9 years) </li>
 <li>Calcudoko (V/9-10years) </li>
 <li> Elephants' Toothpaste </li>
 </ol>

 </div>
 </div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Opening Ceremony</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-1.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-2.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-3.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-4.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-5.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-6.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-7.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny25-8.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Glimpses of the Events</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-1.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-2.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-3.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-4.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-5.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-6.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-7.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-8.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-9.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-10.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-11.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-12.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-13.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-14.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-15.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-16.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-17.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-18.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-19.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-20.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-21.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-22.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-23.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse25-24.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Closing Ceremony</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-1.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-2.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-3.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-4.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-5.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-6.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-7.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-8.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
  <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-9.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
   <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-10.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-11.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
     <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny25-12.jpeg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
</div> 
    </div>

<div className="tab-pane fade" id="pills-Three" role="tabpanel" aria-labelledby="pills-Three-tab"> 
<div className="row">
 <div className="col-md-12">
 <h3>St. Xavier’s Luminaire, 2023-24</h3>
 <p><b>Interschool Competition <br/>
 LKG – Class V <br/></b></p>

 <img src="Images/event-logo23.jpg" className="img-fluid mx-auto d-block event-logo"/>
 <p><b>'St. Xavier's Luminaire, 2023-24 - The Light In You'</b></p>
  <p> Ebullience and zest swathed the purlieu of St. Xavier’s School, Bathinda as its primary wing hosted a two- day event ‘Luminaire’ consisting of a plethora of inter- school competitions on 18th and 19th October, 2023. The entire show was based on the theme of instigating the spirit of healthy competition among children of different schools along with recognising and polishing their hidden talents which was beautifully showcased through myriad celebrations replete with music, dazzling dances, enactments and hues of colours amidst great zeal, vibrancy and elation. The function took place under the auspicious presence of Rev. Father Amaro Martins, Manager, Rev. Father Cidloy Furtado, Principal, Rev. Father Sony Furtado, Rev Father Venito Colaco and the senior dignitaries. The chief guest for the event was Ms Inayat (PCS) SDM, Bathinda.</p>

  <p> The Chief guest for the second day was Ms Lovejeet Kalsi (PCS), ADC, Bathinda. Day-2 events were based on sports to judge the physical strength and stamina of the participants. Various activities like Broad Jump, Relay Race, Skating, Scooter Race etc. were organised wherein the students of participating schools performed remarkably well.</p>
<p>
 Winning School Trophy was bagged by-

 <b>Delhi Public School, Bathinda</b>
</p>

 </div>
 <br/>
 <div className="col-md-12">
 <h3>Schools Participating </h3>
 <p><strong>St. Xavier's Luminaire, 2023-24</strong></p>
 <div className="innertable">
 <table width="100%">
 <tbody><tr>
 <th>Sr.No</th>
 <th>Name of school</th>
 <th>City</th>
 </tr>
 <tr>
 <td>1</td>
 <td>St. Xavier's School</td>
 <td>Bathinda</td>
 </tr>
 <tr>
 <td>2</td>
 <td>Global Discovery School</td>
 <td>Rampura Phul</td>
 </tr>
 <tr>
 <td>3</td>
 <td>St. Kabir Convent Senior Secondary School</td>
 <td>Bathinda</td>
 </tr>
 <tr>
 <td>4</td>
 <td>Delhi Public School</td>
 <td>Bathinda</td>
 </tr>
 <tr>
 <td>5</td>
 <td>St Joseph's Convent Punjabi High School</td>
 <td>Bathinda</td>
 </tr>
 <tr>
 <td>6</td>
 <td>Guru Nanak Dev Public Senior Secondary School</td>
 <td> Bathinda</td>
 </tr>
 <tr>
 <td>7</td>
 <td>Star Plus Convent School</td>
 <td> Raman</td>
 </tr>
 <tr>
 <td>8</td>
 <td>Innovative Global School</td>
 <td>Bathinda</td>
 </tr>
  <tr> <td> 9 </td> <td>  PKS International School</td> <td> Bathinda </td> </tr>
 <tr> <td> 10 </td> <td> Sri Guru Harkrishan Public School </td> <td>    Bathinda </td> </tr>
 <tr> <td> 11 </td> <td> Kidzee Pre School </td> <td>    Bathinda </td> </tr>
 <tr> <td> 12 </td> <td> Arya Model Senior Secondary School </td> <td>   Bathinda </td> </tr>
 <tr> <td> 13 </td> <td> Sri Guru Hargobind Senior Secondary School </td> <td>   Bathinda </td> </tr>
 <tr> <td> 14 </td> <td> Army Public School </td> <td>   Bathinda </td> </tr>
 <tr> <td> 15 </td> <td> The Oxford Public School </td> <td> Cheema </td> </tr>
 </tbody></table>
 </div>



 </div>
 <br/>
 <div className="row">
 <h3>Events List </h3>
 <div className="col-md-6">
 <p><strong>Events Day 1</strong></p>
 <ol>
     <li> The Celebrity Stops By (Lower Kindergarten/3-4 years) </li>
     <li> Budding Newscaster (Upper Kindergarten/ 4-5 years) </li>
     <li> Flashback (1/5-6 years) </li>
     <li> Deco Caricature (01/6-7 Years) </li>
     <li> Beetle and Battle (01/7-8years) </li>
     <li> Pointillism (IV/ 8-9 years) </li>
     <li> Acting Arena (V/9-10 years) </li>
     <li> All Together </li>

 </ol>
 </div>
 <div className="col-md-6">
 <p><strong>Events Day 2</strong></p>
 <ol>
     <li> Hurdle Race (Lower Kindergarten/ 3-4 years) </li>
     <li> Ball Toss (Upper Kindergarten/ 4-5 years) </li>
     <li> Skating Scooter Race (1/5-6 years) </li>
     <li> Broad Jump (II/6-7 Years) </li>
     <li> Karate (III/7-8 Years) </li>
     <li> Roll-A-Thon (IV/8-9 years) </li>
     <li> Battle of Minds (V/9-10years) </li>
     <li> Relay Race </li>
     <li> Hiss & Hop </li>
 </ol>

 </div>
 </div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Opening Ceremony</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-1.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-2.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-3.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-4.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-5.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-6.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-7.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny24-8.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Glimpses of the Events</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-1.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-2.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-3.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-4.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-5.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-6.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-7.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-8.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-9.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-10.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-11.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-12.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-13.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-14.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-15.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-16.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-17.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-18.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-19.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-20.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-21.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-22.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-23.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24-24.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 </div>
 <br/>

 <div className="row g-3">
 <h3> Closing Ceremony</h3>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-1.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-2.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-3.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-4.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-5.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-6.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-7.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
 <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny24-8.jpg" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
</div> 
    </div>

       <div className="tab-pane fade" id="pills-Two" role="tabpanel" aria-labelledby="pills-Two-tab">
           <div className="row">
    <div className="col-md-12">
    <h3>St. Xavier’s Luminaire, 2022-23</h3>
    <p><b>Interschool Competition 
    LKG – Class V </b></p>

    <img src="Images/event-logo22.JPG" className="img-fluid mx-auto d-block event-logo"/>
    <p><b>'St. Xavier's Luminaire, 2022-23 - Children Deserve a Better World'</b></p>
        <p>Setting the tradition, St. Xavier's School, Bathinda organised 'St. Xavier's Luminaire, 2022-23' in its second consecutive year which was scheduled on 3rd and 4th February, 2023 in the school's junior wing campus.</p>

    <p>Dr. V.K. Aggarwal, a renowned professor of English Literature graced the occasion with his presence. Rev Father Christopher Michael, Manager, Rev  Father Cidloy Furtado, Principal, Rev Father Anthony, Rev Father Ivo Dias and Rev Father Lino Santiago also marked their presence on the opening ceremony.</p>

    <p>The program was an inter-school competition which comprised of various events related to sports, public speaking, theatre, critical thinking and creativity. Various schools participated in this program with full zeal and enthusiasm.</p>

    <p>It was a two day affair where one day was dedicated to the sports events and the other comprised of events evaluating other skills.</p>

    <p>The closing ceremony was graced by Mr. Rupinder Singh, District Sports Officer along with Rev Father Christopher Michael, Manager and Rev Father Cidloy Furtado, Principal. The event was sponsored by Bakesbear, Indian Oil Baldev Auto Fuels and B'Smart Dresses.</p>

   <p>
    Winning School Trophy was bagged by-
   
    <b> Star Plus Convent School, Raman</b>
   </p>

    </div>
    <br/>
    <div className="col-md-12">
    <h3>Schools Participating </h3>
    <p><strong>St. Xavier's Luminaire, 2022-23</strong></p>
    <div className="innertable">
    <table width="100%">
    <tbody><tr>
    <th>Sr.No</th>
    <th>Name of school</th>
    <th>City</th>
    </tr>
    <tr>
    <td>1</td>
    <td>St. Xavier's School</td>
    <td>Bathinda</td>
    </tr>
    <tr>
    <td>2</td>
    <td>St. Kabir Convent School</td>
    <td>Bathinda</td>
    </tr>
    <tr>
    <td>3</td>
    <td>Rose Mary Convent School </td>
    <td>Bathinda</td>
    </tr>
    <tr>
    <td>4</td>
    <td>St Joseph's High School</td>
    <td>Mandi Dabwali</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Star Plus Convent School</td>
    <td>Raman</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Sri Guru Hargobind Convent Senior Secondary School</td>
    <td> Nathana</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Army Public School</td>
    <td> Bathinda Cantt</td>
    </tr>
    <tr>
    <td>8</td>
    <td>The Oxford Public School</td>
    <td>Cheema</td>
    </tr>
    </tbody></table>
    </div>



    </div>
    <br/>
    <div className="row">
    <h3>Events List </h3>
    <div className="col-md-6">
    <p><strong>Events Day 1</strong></p>
    <ol>
    <li>  Tasty Twisty Bites (Lower Kindergarten/3-4 Years) </li>
    <li> Vibratones (Upper Kindergarten/ 4-5 Years) </li>
    <li>  Slow Toes (1/5-6 Years) </li>
    <li> Dilli Dally (II/6-7 Years) </li>
    <li>  Thespianism (III/7-8 Years) </li>
    <li>  Giggles Galore (IV/8-9 Years) </li>
    <li>  Chitter Chatter of the Big Enchiladas (V/9-10 Years) </li>
    <li>  Clash of Clans -1 </li>

    </ol>
    </div>
    <div className="col-md-6">
    <p><strong>Events Day 2</strong></p>
    <ol>
    <li> Animal Walk (Lower Kindergarten/3-4 years) </li>
   <li> Spiritual Bliss (Upper Kindergarten/4-5 years) </li>
   <li> Basket the Ball (1/5-6 years) </li>
   <li> Stabilize Steps(II/6-7 Years) </li>
   <li> Karate (III/7-8 Years) </li>
   <li> Knock It Out (IV 8-9 Years) </li>
   <li> Flying Gravity (V/9-10 Years) </li>
   <li> Clash of Clans - II </li>


    </ol>

    </div>
    </div>
    </div>
    <br/>

    <div className="row g-3">
    <h3> Opening Ceremony</h3>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny1.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny2.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny3.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny4.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny5.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny6.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny7.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/openingceremny8.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    </div>
    <br/>

    <div className="row g-3">
    <h3> Glimpses of the Events</h3>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse1.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse2.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse3.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse4.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse5.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse6.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse7.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse8.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse9.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse10.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse11.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse12.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse13.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse14.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse15.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse16.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse17.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse18.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse19.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse20.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse21.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse22.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse23.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Glimpse24.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    </div>
    <br/>

    <div className="row g-3">
    <h3> Closing Ceremony</h3>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny1.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny2.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny3.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny4.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny5.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny6.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny7.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/Closingceremny8.JPG" className="img-fluid img-thumbnail"/></SlideshowLightbox></center></article></div>
  </div> 
       </div>
       <div className="tab-pane fade" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab"> 
<div className="row">
<div className="col-md-12">
<h3>St. Xavier’s Luminaire, 2021 </h3>
<p><b>Interschool Competition
LKG – Class V
</b></p>

<img src="Images/event-logo.jpg" className="img-fluid mx-auto d-block"/>
<p><b>‘St. Xavier’s Luminaire, 2021-Bloom Where You Are Planted’</b> our annual Interschool Competition made its debut in the year in offline as well as online mode.
An endeavour planned under the guidance of our Principal Rev Fr. Cidloy Furtado and Manager Rev Fr. Christopher Michael to identify children’s interests early on and support them to build on their talents, explore new frontiers and connect with real world situations.
<br/>
Various events were held from 26th – 30th Nov 2021 wherein students from a number of prestigious schools participated. The event was sponsored by Bank of Baroda, Aakash-Byju’s, Volkswagen, Entab, B’Smart Dresses, Saachi Fabrication &amp; Steel Industry and Satjeet &amp; Co. It was an absolute delight to see the children in great numbers. The schools which could not participate because of distance joined us in the online events. Rev. Fr. Ivo Dias, Principal, St. Joseph's High School, Mandi Dabwali was the chief guest on Day 1 and Ms Vivya Arora , Principal of Dashmesh International Public School, Malout was the chief guest on Day 2.   All the participants gave their best and amazed every one with their performance and brought laurels to their school.
<br/>
Winning School Trophy was bagged by-
<br/>
<b>Offline - Star Plus Convent School, Raman</b>
<br/>
<b>Online - Fr. Agnel School, New Delhi</b></p>

</div>
<br/>
<div className="col-md-12">
<h3>Schools Participating </h3>
<p><strong>St. Xavier's Luminaire, 2021 </strong></p>
<div className="innertable">
<table width="100%">
<tbody><tr>
<th>Sr.No</th>
<th>Name of school</th>
<th>City</th>
</tr>
<tr>
<td>1</td>
<td>Star Plus Convent School</td>
<td>Raman, Bathinda</td>
</tr>
<tr>
<td>2</td>
<td>Silver Oaks School, Sushant City-II </td>
<td>Bathinda</td>
</tr>
<tr>
<td>3</td>
<td>Silver Oaks School, Dabwali Road </td>
<td>Bathinda</td>
</tr>
<tr>
<td>4</td>
<td>St. Kabir Convent Sen Sec School </td>
<td>Bhucho Khurd, Bathinda</td>
</tr>
<tr>
<td>5</td>
<td>St. Joseph's High School </td>
<td>Mandi Dabwali</td>
</tr>
<tr>
<td>6</td>
<td>Kendriya Vidyalaya No. 4</td>
<td> Bathinda Cantt.</td>
</tr>
<tr>
<td>7</td>
<td>Delhi Public School</td>
<td> Bathinda</td>
</tr>
<tr>
<td>8</td>
<td>Kendriya Vidyalaya No. 3 </td>
<td>AFS, Bhisiana, Bathinda</td>
</tr>
<tr>
<td>9</td>
<td>Mount Litera Zee school </td>
<td>Bathinda</td>
</tr>
<tr>
<td>10</td>
<td>St. Xavier's School, Rampura Phul </td>
<td>Rampura Phul, Bathinda</td>
</tr>
<tr>
<td>11</td>
<td>Sri Guru Harkrishan Public School</td>
<td> Bathinda</td>
</tr>
<tr>
<td>12</td>
<td>Akal Academy, Bangi Nihal Singh</td>
<td> Bathinda</td>
</tr>
<tr>
<td>13</td>
<td>Fr. Agnel School </td>
<td>New Delhi</td>
</tr>
<tr>
<td>14</td>
<td>Fr. Agnel School</td>
<td> Noida</td>
</tr>
<tr>
<td>15</td>
<td>St. Xavier's High School </td>
<td>Rania, Sirsa</td>
</tr>
<tr>
<td>16</td>
<td>St. Xavier's School </td>
<td>Uklana, Hisar</td>
</tr>
<tr>
<td>17</td>
<td>The Emerald Heights International School</td>
<td> Rau, Indore</td>
</tr>
<tr>
<td>18</td>
<td>Niscort Fr. Agnel School </td>
<td>Vaishali, Ghaziabad</td>
</tr>
<tr>
<td>19</td>
<td>The Sirsa School Junior Wing</td>
<td> Sirsa</td>
</tr>
<tr>
<td>20</td>
<td>St. Francis Xavier's School</td>
<td> Hisar</td>
</tr>
<tr>
<td>21</td>
<td>Fr. Agnel School, Waliv, Vasai (E) </td>
<td>Vasai</td>
</tr>
<tr>
<td>22</td>
<td>Sacred Heart Convent School </td>
<td>Jagraon</td>
</tr>
<tr>
<td>23</td>
<td>Eastwood International School </td>
<td>Mandi Dabwali</td>
</tr>
</tbody></table>
</div>



</div>
<br/>
<div className="row">
<h3>Events List </h3>
<div className="col-md-6">
<p><strong>Events Day 1</strong></p>
<ol>
<li>Actomania (Kindergarten 3-4 years)</li>
<li>Think Station (Kindergarten 4-5 years) </li>
<li>My Kitchen My Healer (Class I) </li>
<li>Rap Battle (Class 2)</li>
<li>Funny Bones (Class 3) </li>
<li>Speculate and Annotate (Class4)</li>
<li> Decor Metry (Class 5) </li>
<li>ExploraVision (Kindergarten 3-4 years) </li>
<li>Sizzle and Stomp (Kindergarten 4-5 years) </li>
<li>Canvas Kids (Class I)</li>
<li> Gaggles of Gifts (Class 2)</li>
<li>T-Shirt Tales (Class 3) </li>
<li>Pixel Art (Class 4)</li>
<li>Natak Nautanki (Class 5)</li>

</ol>
</div>
<div className="col-md-6">
<p><strong>Events Day 2</strong></p>
<ol>
<li>Sweet Peas (Kindergarten 3-4 years) </li>
<li>Hocus Pocus (Kindergarten 4-5 years) </li>
<li>Cave Art (Class I)</li>
<li>Eco-Couture (Class 2) </li>
<li>Story to Stage-A celebration of Imagination (Class 3) </li>
<li>Dramebaaz (Class 4)</li>
<li>Once Upon a Time-Indie Folk Tales (Class 5) </li>
<li>Master Meals-Fresh Feast (Kindergarten 3-4 years)</li>
<li>Waste to Wonder (Kindergarten 4-5 years) </li>
<li>The Morning Rise (Class I)</li>
<li>Play-Street Style! (Class 2)</li>
<li>Green Green Home (Class 3) </li>
<li>Frisk on Motley Disc (Class 4) </li>
<li>Meet Junior Robert Gaskin (Class 5) </li>


</ol>

</div>
</div>
</div>
<br/>

<div className="row g-3">
<h3> Opening Ceremony</h3>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-1.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-2.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-3.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-4.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-5.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-6.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-7.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/OC-8.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
</div>
<br/>

<div className="row g-3">
<h3> Glimpses of the Events</h3>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 1.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 2.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 3.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 4.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 5.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 6.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 7.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 8.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center><SlideshowLightbox><img src="Images/PC 9.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 10.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 11.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 12.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 13.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 14.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 15.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 16.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 17.jpg" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 18.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 19.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center> <SlideshowLightbox><img src="Images/PC 20.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
</div>
<br/>

<div className="row g-3">
<h3> Closing Ceremony</h3>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 1.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 2.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 3.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 4.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 5.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 6.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 7.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
<div className="col-lg-3 col-md-3 col-sm-6 col-6 fullwidth550"><article><center>  <SlideshowLightbox><img src="Images/CC 8.JPG" className="img-fluid"/></SlideshowLightbox></center></article></div>
</div>
</div>
 </div>
 </div>
 </div>


 <Footer/>

  </>
  )
}

export default Luminaire
