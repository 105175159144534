import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
const PresidentMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  
     <div className="row bread_cumb">
     <h2>President's Message</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Message <i className="bi bi-arrow-right"></i> President's Message</span></h5>
     </div>
     
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     
     <article>
     <img src="Images/president.jpg" width="282" height="222" layout="responsive" alt="St. Xaviers Bathinda"/>
     <h3>President's Message</h3>
     
     <p><b>Dear Parents, Staffs and Students,</b></p>
         <p>As we navigate the journey of life, it's essential to reflect on the values that shape our character and guide our actions. Allow me to share with you timeless words of wisdom discovered on the walls of an orphanage in Calcutta, India:</p>
         <p>"If you are kind, people may accuse you of selfish, ulterior motives. Be kind anyway."</p>
         <p>In a world where cynicism can cloud our intentions, let kindness be our compass. Despite the skeptics, let our actions be driven by genuine empathy and compassion.</p>
         <p>"When you spend years building, someone could destroy overnight. Build anyway."</p>
         <p>The path to success is often fraught with obstacles, and setbacks may test our resolve. However, let us persevere in our endeavors, knowing that the fruits of our labor are worth the journey, regardless of the challenges we may face.</p>
         <p>"The good you do today, people often forget tomorrow. Do good anyway."</p>
         <p>Our actions have the power to leave a lasting impact, even if they go unnoticed or unappreciated. Let us continue to sow seeds of goodness, knowing that even small acts of kindness can ripple through time and space.</p>
         <p>"Give the best to the world you have today, and it may never be enough. Give the best you have got anyway."</p>
         <p>Excellence is not defined by perfection but by our unwavering commitment to give our all, even when circumstances may seem less than ideal. Let us strive for excellence in all that we do, knowing that our efforts contribute to the betterment of the world around us.</p>
         <p>In the final analysis, it is between you and your God anyway.</p>
         <p>Ultimately, our choices and actions are accountable to our conscience and our Creator. Let us live with integrity and purpose, knowing that our deeds shape not only our destinies but also the world we leave behind.</p>
         <p>As we embrace these timeless principles, may we find fulfillment in the journey of life, knowing that our actions, no matter how small, have the power to make a difference.</p>
         <p>With warm regards,</p>
         <p><b></b></p>
             <div className="clr20"></div>
     
             <h4>Fr. Remy Cardozo</h4>
             <h5><strong>President</strong></h5>
     </article>
     
     <div className="clr"></div>
     </div>
     <MessageSidebar/>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default PresidentMessage
