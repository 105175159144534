import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import ActivitiesSidebar from'./ActivitiesSidebar'
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
const NccArmy = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/> 
     <div className="row bread_cumb">
     <h2>Ncc (Army)</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span> Activities <i className="bi bi-arrow-right"></i> Ncc (Army)</span></h5>
     </div>
     
     </div>
     <div className="innerpage">
<div className="row inner_cont">
<div className="col-lg-9 col-md-8 col-sm-12 col-12 memories_sec">
        <div className="clr"></div>
        <h5>*ATC 57 Camp </h5>
        <p>A sneak peek into the ATC 57 camp organised by National Cadet Corps from 1st to 10th June 2023. The aim of this camp was to develop leadership skills, courage, honesty ,a sense of responsibility in the cadets. It was an adventurous experience where Xavierites participated in this camp with cadets from various other schools and learnt important life skills.</p>
        <div className="row">
         <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/57-Camp-1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/57-Camp-2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/57-Camp-3.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
             <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/57-Camp-4.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>

          
          
        </div>


           <div className="clr"></div>
      <h5>*International Yoga Day</h5>
      <p>'Yoga is a mirror to look within our soul.'<br />
Yoga is an integral part of our cultural and spiritual heritage.<br />
Twenty five cadets from NCC army of St. Xavier's School, Bathinda enthusiastically participated in the mass yoga session organised at Dana Mandi on June 21,2023. Dressed in their sports attire, Xavierites gracefully performed a variety of yoga asanas thus inspiring everyone to make yoga an integral part of their lives to promote physical and mental well-being.
</p>

      <div className="row">
         <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/Yoga-Day-23-1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/Yoga-Day-23-2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
           <div className="col-lg-4 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/Yoga-Day-23-3.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          
        </div>


         <div className="clr"></div>
		 <h5>* Once a life lived for others is a life worth living</h5>
        <p>With this motto saint Xavier’s Bathinda is training its students for the NCC, National Cadet Corps. Xavier's started it in 2019. It is guided by the unit 20 Punjab Battalion NCC 
        Bathinda, under the mentorship of the commanding officer Lieutenant Col Ajay Kumar. School provides 50 classes every year to its students. Under which 25 students are trained in 
        each session and they are taught about drills and other things related to it. Once students are done with their training session they are given an 'A' certificate that helps them 
        to get admission in the further studies. NCC believes in developing characters, comradeship and the ideas of selfless service amongst the budding army officers. Students are provided 
        with the practical training and opportunities of exposure so that they can pave the way in their coming future and can be an asset for the nation. our training tries to inculcate values 
        like social services by organising activities like tree plantation and 'Swachh Bharat Abhiyan' and so on.</p>
        <div className="row">

         <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/2022-23.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              <h4>2022-23</h4>
            </article>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/2021-22.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              <h4>2021-22</h4>
            </article>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/2020-21.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              <h4>2020-21</h4>
            </article>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/2019-20.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              <h4>2019-20</h4>
            </article>
          </div>
        </div>
        <div className="clr"></div>

        <h5>* Oath Taking</h5>
        <p>Oath taking ceremony conducted by the cadets of our St. Xavier school on swachh Bharat drive against the increasing and massive use of plastic nowadays.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/Oath-Taking-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                  <SlideshowLightbox><img src="Images/Oath-Taking-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Statue Cleaning</h5>
        <p>Oath taking ceremony conducted by the cadets of our St. Xavier school on swachh Bharat drive against the increasing and massive use of plastic nowadays.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Statue-Cleaning-1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Statue-Cleaning-2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Statue-Cleaning-3.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Statue-Cleaning-4.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Tree Plantation</h5>
        <p>The tree plantation activity was carried out at Bir Talab (village), Bathinda under the coordination of sarpanch Mrs. Parminder Kaur. The purpose was to spread awareness for planting more and more trees to keep the surrounding green and clean. </p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Tree-Plantation-1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Mock Drill</h5>
        <p>NCC army cadets of the St. Xavier school did a mock drill on disaster management under the guidance of 7 BN NDRF Bathinda. The NDRF and NCC cadets displayed the assessment that was shown to them. </p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Mock-Drill-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Stubble Burning</h5>
        <p>NCC army cadets of St. Xavier school Bathinda conducted the social service community development activity on 'stubble burning'. An awareness camp was organized with creative posters, speeches and articles at a village in Bir Talab.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/Stubble-Burning-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>	
        <div className="clr"></div>
        <h5>* Earth Day</h5>
        <p><strong>Go Green! Plastic is obscene!</strong></p>
		<p>NCC Army Cadets of St. Xavier's School, Bathinda contributed in making an eco-friendly garden.</p>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/army-Earth-Day-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/army-Earth-Day-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-6 fullwidth550">
            <article>
              <center>
                   <SlideshowLightbox><img src="Images/army-Earth-Day-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
        <div className="clr"></div>
        <h5>* Yoga Day</h5>
        <p><strong>'Health is Wealth'</strong></p>
		<p>Yoga day celebration was conducted on 30th May, 2022 in St. Joseph School, Bathinda. The rationale of this event was to spread awareness about living a fit and healthy life. A great deal of enthusiasm and zeal was shown by all the students.</p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <SlideshowLightbox><img src="Images/army-Yoga-Day-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                    <SlideshowLightbox><img src="Images/army-Yoga-Day-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
		  <div className="clr"></div>
        <h5>* ATC-115 - Annual Training Camp </h5>
<p>ATC-115, Annual Training Camp of NCC Army.</p>
		   <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/ATC-115-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/ATC-115-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/ATC-115-3.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/ATC-115-4.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>
		  <div className="clr"></div>
        <h5>*Firing Drill</h5>
		<p>NCC Army Cadets of St. Xavier's School, Bathinda learnt how to use rifles to strengthen the mental ability.</p>
		   <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-3.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-4.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-5.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                     <SlideshowLightbox><img src="Images/Firing-Drill-6.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
			   
        </div>
		  
		  <div className="clr"></div>
		<h5>*Best Cadet - CWS</h5>
		<p>Ekamtegbir Singh of class X D and Shubhneet Kaur of class X E were rewarded with the scholarship for the best cadet in the Patiala group by Cadet Welfare Society (CWS).</p> 
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/Best-Cadet-1.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/Best-Cadet-2.jpeg" className="img-fluid"/></SlideshowLightbox>
              </center>
            </article>
          </div>
        </div>		  
        <div className="clr"></div>
              <h5>*National Unity Day</h5>
        <p>NCC Army cadets of St. Xavier's School, Bathinda celebrated National Unity Day at Sports Stadium, Bathinda. NCC Cadets took out a rally to raise awareness among the masses, to celebrate the birth anniversary of Sardar Vallabhbhhai Patel.  </p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/army-unity-day-1.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              
            </article>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 fullwidth550">
            <article>
              <center>
                 <SlideshowLightbox><img src="Images/army-unity-day-2.jpg" className="img-fluid"/></SlideshowLightbox>
              </center>
              
            </article>
          </div>          
        </div>
        <div className="clr"></div>
        </div>
<ActivitiesSidebar/>
</div>
</div>
   <Footer/>
  </>
  )
}

export default NccArmy
