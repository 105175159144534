import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getEventsinner } from '../../Service/Api';

const JuniorEvents = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getEventsinner();
      console.log("Fetched data:", galleryData); // Debugging log
      setData(galleryData);
      const uniqueYears = Array.from(new Set(galleryData.map(item => item.date ? new Date(item.date).getFullYear() : null))).filter(year => year != null);
      uniqueYears.sort((a, b) => b - a);
      setYearRanges(uniqueYears);
      const uniqueMonths = Array.from(new Set(galleryData.map(item => item.date ? new Date(item.date).getMonth() : null))).filter(month => month != null);
      uniqueMonths.sort((a, b) => a - b);
      setMonthOptions(["All", ...uniqueMonths.map(month => monthNames[month])]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (selectedYear !== "All") {
      filteredData = filteredData.filter(item => item.date && new Date(item.date).getFullYear() === parseInt(selectedYear));
    }
    if (selectedMonth !== "All") {
      filteredData = filteredData.filter(item => item.date && monthNames[new Date(item.date).getMonth()] === selectedMonth);
    }
    if (searchQuery) {
      filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    console.log("Filtered Data after all conditions:", filteredData); // Debugging log
    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  };

  const emptyArray = [
    { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "Images/gallery-pic.jpg" },
    { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "Images/gallery-pic.jpg" },
    { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "Images/gallery-pic.jpg" },
    { title: "No data found", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "Images/gallery-pic.jpg" },
];

  return (
    <>
      <div className="kids">
        <div className="container juniorcontainer">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <Link to="/JuniorHome"><img src="/JuniorImages/logo.png" className="img-fluid logo" alt="St. Xaviers Bathinda" /></Link>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <Link to="/JuniorHome"><img src="/JuniorImages/kids.png" className="img-fluid kids-logo" alt="St. Xaviers Bathinda" /></Link>
              <div className="kintergarten_slider_sec_btm_bird"><img src="/JuniorImages/bird.gif" className="img-fluid"/></div>
            </div>
          </div>
          <div className="kids-back-sec">
   <h2> Events</h2>
   
   <div className="news-inner">
   
   <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                    <option value="All">All Years</option>
                                    {yearRanges.map((yr, index) => (
                                        <option key={index} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                    <option value="All">All Months</option>
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Search Here.."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2024 showYear">
                        {filterData.length > 0 ? (
                            filterData?.filter((item) =>   item.school === "Both" || item.school === "Junior").map((item, index) => (
                               
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" key={index}>
                                    <div className="newsbox">
                                        <div className="newsboximg">
                                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images[0]}`} className="img-fluid" alt="" />
                                        </div>
                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                                <span><b>Location: </b> {item.venue}</span>
                                            </div>
                                            <div className="newsdate">
                                                <span><i className="bi bi-calendar"></i> {formatDate(item.date)}</span>
                                                <span><i className="bi bi-alarm"></i> {item.time}</span>
                                                <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            emptyArray.map((data, index) => (
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount" key={index}>
                                    <div className="newsbox">
                                        <div className="newsboximg">
                                            <img src={data.attachments} className="img-fluid" alt="" />
                                        </div>
                                        <div className="newsboxdesc">
                                            <div className="newspgh">
                                                <h5>{data.title}</h5>
                                                <p>{data.description}</p>
                                                <span><b>Location: </b> {data.venue}</span>
                                            </div>
                                            <div className="newsdate">
                                                <span><i className="bi bi-calendar"></i> {data.date}</span>
                                                <span><i className="bi bi-alarm"></i> {data.time}</span>
                                                <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
</div>
          
    </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JuniorEvents;
