import React from 'react'
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import MessageSidebar from'./MessageSidebar'
import { Link } from 'react-router-dom'
const ManagerMessage = () => {
  return (
    <>
<Header/>
     <div className="inner_slider">
     <img src="/Images/inner_slider.jpg" className="img-fluid inner_slider_img" alt="St. Xaviers Bathinda"/>  
     <div className="row bread_cumb">
     <h2>Manager's Message</h2>
     <div className="theme-heading">
     <span className="colorW marB10"><i className="bi bi-building"></i></span>
     </div>
     <h5><Link to="/">Home <i className="bi bi-arrow-right"></i> </Link><span>Message <i className="bi bi-arrow-right"></i> Manager's Message</span></h5>
     </div>
     
     </div>
     <div className="clr"></div>
     <div className="innerpage">
     <div className="row inner_cont">
     <div className="col-lg-9 col-md-8 col-sm-12 col-12 message_inner">
     
     <article>
     <img src="Images/manager.jpg" width="282" height="222" layout="responsive" alt="St. Xaviers Bathinda"/>
     <h3>Manager's Message</h3>
     
     <p>‘Give a man a fish and you feed him for a day; teach a man to fish and you feed him for a lifetime.’ Teaching a person, a useful skill can be more beneficial in the long run than filling a need for them temporarily, that’s basically the meaning of this proverb. It focuses on the idea that long-term benefits are more useful than short-term benefits. I firmly believe that an educational institute is not just about infrastructure but about building persons’ character, and enlightening minds that will lead them toward lifetime growth. Doing and learning, analyzing, and applying is the elegance of St. Xavier’s School. We look forward to integrated teaching and learning as per NEP 2020. We are striving to provide our students with an atmosphere for multifaceted development where every child is encouraged to channelize their potential in the pursuit of excellence with a friendly environment, warmth, and individual care that will undoubtedly tap the inherent potential that exists in every individual.</p>
     <p>We hope you enjoy exploring our school website to get an idea of how learning is integrated into a creative way of harnessing talent. And let us work together to set new standards and redefine our potential for the betterment of our Children. </p>
     
     
         <div className="clr20"></div>
     
             <h4> Fr. Amaro Martins</h4>
             <h5><strong>Manager</strong></h5>
     </article>
     
     <div className="clr"></div>
     </div>
     <MessageSidebar/>
     <div className="clr"></div>
     </div>
     
     <div className="clr"></div>
     </div>
     <div className="clr"></div>
   <Footer/>
  </>
  )
}

export default ManagerMessage
